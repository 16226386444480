import ButtonV2 from "elements/ButtonV2";
import CircleCheck from "public/account/survey/concierge/circle-check.svg";
import { Tooltip3 } from "elements/Tooltip";
import { PlanFeature } from "./ConciergeOptionCard";
import { useState } from "react";
import InfoCircle from "public/info-circle.svg";
import HelpTextModal from "./HelpTextModal";
import { usePropertyContext } from "../context";

const getAllFeatures = (propertyInServicingAppeal: boolean): PlanFeature[] => {
	const baseFeatures: PlanFeature[] = [
		{
			active: false,
			label: "1 on 1 Consultation Onboarding",
			helpText:
				"Get a thorough, 1 on 1 meeting with your assigned Concierge expert to review every aspect of your property for maximum savings. After subscribing and completing your portal onboarding, you'll be notified to either schedule a call or fill out the digital Concierge questionnaire at your convenience.",
		},
	];

	if (propertyInServicingAppeal) {
		baseFeatures.push({
			active: true,
			label: "Annual Property Tax Appeals",
			helpText:
				"Never overpay on your property taxes again with a high quality property tax appeal each and every year. You only pay if you save!",
		});
	}
	return [
		...baseFeatures,
		{
			active: true,
			label: "Exemption Filing",
			helpText:
				"Primary residence exemptions can further lower your property tax bill. We'll handle every step—from preparing all necessary documents to filing and activating your exemption—while also pursuing any eligible refunds.",
		},
		{
			active: false,
			label: "Exemption Fall Off Monitoring",
			helpText:
				"If we detect that a Concierge property's primary residence exemption has fallen off, which can lead to a severe loss of savings, we'll notify you and help to get it reinstated.",
		},
		{
			active: false,
			label: "Mortgage Reduction",
			helpText:
				"Monitor your mortgage and equity like a pro! Our Concierge team can help qualified subscribers unlock additional savings through potential refinancing, PMI removal, and more.",
		},
		{
			active: false,
			label: "Insurance Reduction",
			helpText:
				"We'll periodically review your home insurance to uncover potential savings. When an opportunity arises, we'll notify you and collaborate with our partners to secure those savings. Concierge subscriber's policy documents are vetted thoroughly to ensure all opportunities to save are uncovered.",
		},
		{
			active: false,
			label: "Utility Reduction",
			helpText:
				"In qualifying regions, we can help determine if your overpaying on you're current energy bill. Concierge subscriber's energy bills are reviewed regularly and you will be notified when the time is right to make a switch.",
		},
		{
			active: false,
			label: "Internet Bill Reduction",
			helpText:
				"Our Bill Reduction Experts can negotiate and work to lower your internet bill directly with your provider so you don't have to! Concierge customer's current bill is vetted to ensure all possible savings are being fully utilized.",
		},
		{
			active: false,
			label: "Unclaimed Property",
			helpText:
				"We'll search across Concierge subscriber's previous addresses to help to uncover and retrieve any money that might be due to you. This happens sometimes when an entity, organization or business isn't able to get money back to you, in which case it ends up as unclaimed property.",
		},
		{
			active: false,
			label: "Trust Creation",
			subtext: "(optional)",
			helpText:
				"We help our Concierge subscribers determine if creating a trust makes sense for their property and goals. Trusts help to avoid costly probate in the future, create anonymity and can have multiple trustees as needed.",
		},
		{
			active: false,
			label: "Local reports",
			subtext: "(home values, crime, etc.)",
			helpText:
				"Access neighborhood crime reports and local construction permit details to stay on top of what's happening around your property.",
		},
	];
};

const NoConciergeOptionCard = ({
	contingencyPercentage,
	onOptionSelect,
	hideCTA = false,
}: {
	contingencyPercentage: number;
	onOptionSelect: () => void;
	appealsServiceAvailable?: boolean;
	hideCTA?: boolean;
}) => {
	const [selectedHelp, setSelectedHelp] = useState<{
		text: string;
		label: string;
	} | null>(null);
	const [showContingencyModal, setShowContingencyModal] = useState(false);

	const { details } = usePropertyContext();

	const featuresList = getAllFeatures(
		details?.accepting_appeals == 1 ? true : false
	);

	return (
		<div className="concierge-options-card basic-option">
			<div className="card-header">
				<p className="card-heading">Basic</p>
			</div>
			<div className="pricing">
				{!hideCTA && <p className="price h4 denim">Included</p>}
				<div className="contingency-box">
					<p
						className="denim-medium flex align-center"
						onClick={() => setShowContingencyModal(true)}>
						{contingencyPercentage ? contingencyPercentage + "% " : "25% "}
						contingency fee
						<InfoCircle className="info-icon fill-denim-5 ml-1" />
					</p>
				</div>
			</div>
			{!hideCTA && (
				<div className="cta">
					<ButtonV2
						variant="primary-outline"
						size="extra-small"
						className="w-100 no-box-shadow"
						onClick={onOptionSelect}>
						Stay With Basic Plan
					</ButtonV2>
				</div>
			)}
			<div className="features">
				<p className="feature-heading">What's Included?</p>
				<p className="xs mb-1 italic">
					Click to learn more about each service!
				</p>
				<div className="feature-list">
					{featuresList.map((item, index) => (
						<div
							className="list-item"
							key={index}
							onClick={() => {
								if (item.helpText) {
									setSelectedHelp({ text: item.helpText, label: item.label });
								}
							}}
							style={{ cursor: item.helpText ? "pointer" : "default" }}>
							<div className="list-item-label">
								<span className={item.active ? "icon" : "icon fill-denim-5"}>
									<CircleCheck />
								</span>
								<div className="list-item-label-text">
									<p className={item.active ? "sm" : "sm denim_5"}>
										{item.label}{" "}
									</p>
									{item.subtext && <p className="xs denim_5">{item.subtext}</p>}
									{item.helpText && (
										<InfoCircle
											className={
												"info-icon " +
												(item.active ? "fill-denim" : "fill-denim-5")
											}
											onClick={(e: React.MouseEvent) => {
												e.stopPropagation();
												if (item.helpText) {
													setSelectedHelp({
														text: item.helpText,
														label: item.label,
													});
												}
											}}
										/>
									)}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			{selectedHelp && (
				<HelpTextModal
					helpText={selectedHelp.text}
					label={selectedHelp.label}
					onClose={() => setSelectedHelp(null)}
				/>
			)}
			{showContingencyModal && (
				<HelpTextModal
					helpText="Our savings-or-free contingency fee is applied to bill savings we achieve for you. For instance, if our fee is 25% and your property tax bill is reduced from $5,000 to $4,000 our fee will be applied to the $1,000 difference. In this case, the final invoice amount would be $250."
					label="Contingency Fee"
					onClose={() => setShowContingencyModal(false)}
				/>
			)}
		</div>
	);
};

export default NoConciergeOptionCard;
