import {
	Dispatch,
	FormEventHandler,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { Label, TextField } from "elements/Input";
import InvalidExclamationIcon from "public/invalid-exclamation.svg";
import formatDollar from "utils/formatDollar";
import { TextFieldProps } from "elements/Input/TextField";
import getCardType from "credit-card-type";
import validateCard from "card-validator";
import client from "utils/client";
import { Invoices } from "utils/types";
import PayingInvoiceAnimation from "public/invoice-paying.gif";
import Image from "next/image";
import axios from "axios";
import PoweredByStripe from "public/powered-by-stripe.svg";

// Card Logos
import VisaLogo from "public/card-logos/visa.svg";
import MastercardLogo from "public/card-logos/master-card.svg";
import AmericanExpressLogo from "public/card-logos/american-express.svg";
import DinersClubLogo from "public/card-logos/diners-club.svg";
import DiscoverLogo from "public/card-logos/discover.svg";
import JcbLogo from "public/card-logos/jcb.svg";
import UnionPayLogo from "public/card-logos/union-pay.svg";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import ButtonV2 from "elements/ButtonV2";
import ConciergeAddOn from "components/elements/ConciergeAddOn";
const CARD_TYPE_LOGO_MAP: Record<string, any> = {
	visa: VisaLogo,
	mastercard: MastercardLogo,
	"american-express": AmericanExpressLogo,
	"diners-club": DinersClubLogo,
	discover: DiscoverLogo,
	jcb: JcbLogo,
	unionpay: UnionPayLogo,
};

const CONCIERGE_ADD_ON_PRICE = 99;

const InvoiceTextField = ({
	invalid,
	wrapperClassName,
	...textFieldProps
}: TextFieldProps & { invalid: boolean; wrapperClassName?: string }) => {
	return (
		<div className={wrapperClassName}>
			<TextField {...textFieldProps} />
			{invalid && (
				<div className="pay-invoice-input-invalid-entry-container">
					<InvalidExclamationIcon />
					<p className="sm terracotta">Invalid Entry</p>
				</div>
			)}
		</div>
	);
};

const CardLogo = ({
	type,
	...svgProps
}: { type: string } & React.SVGProps<SVGSVGElement>) => {
	const Component = CARD_TYPE_LOGO_MAP[type];

	if (!Component) return null;

	return <Component {...svgProps} />;
};

const DEFAULT_INVALID_STATE = {
	cardNumber: false,
	cardHolderName: false,
	expirationDate: false,
	cvc: false,
};

const PayInvoicePaymentForm = ({
	amount,
	onFormSubmit,
	trackFieldValidation,
	errored,
	setErrored,
	showConciergeAddOn = false,
}: {
	amount: string;
	onFormSubmit: (args: {
		card_number: string;
		card_cvc: string;
		card_exp_month: string;
		card_exp_year: string;
		conciergeAddOnSelected?: boolean;
		extraAmount?: number;
	}) => void;
	trackFieldValidation: (fieldName: string) => void;
	errored: boolean;
	setErrored: Dispatch<SetStateAction<boolean>>;
	showConciergeAddOn?: boolean;
}) => {
	const [cardNumber, setCardNumebr] = useState("");
	const [cardHolderName, setCardHolderName] = useState("");
	const [expirationDate, setExpirationDate] = useState("");
	const [cvc, setCvc] = useState("");

	const [cardType, setCardType] = useState("");

	const [conciergeAddOnSelected, setConciergeAddOnSelected] = useState(false);

	const [showInvalidState, setShowInvalidState] = useState(
		DEFAULT_INVALID_STATE
	);

	const [submitting, setSubmitting] = useState(false);

	const trackFieldValidationIssue = useEventTracker();
	const trackInvoiceFailed = useEventTracker();

	const cardTypeInfo = useMemo(() => getCardType(cardNumber), [cardNumber]);

	const validityInfo = useMemo(() => {
		return {
			cardNumber: validateCard.number(cardNumber),
			cardHolderName: validateCard.cardholderName(cardHolderName),
			expirationDate: validateCard.expirationDate(expirationDate),
			cvc: validateCard.cvv(cvc, cardTypeInfo[0]?.code.size),
		};
	}, [cardNumber, cardHolderName, expirationDate, cvc, cardTypeInfo]);

	const cardNumberFieldMaxLength = useMemo(() => {
		if (!cardTypeInfo[0]) return 19;

		const numGaps = cardTypeInfo[0].gaps.length;
		const largestLengthOfCardType = cardTypeInfo[0].lengths.reduce(
			(a, b) => Math.max(a, b),
			0
		);

		return largestLengthOfCardType + numGaps;
	}, [cardTypeInfo]);

	useEffect(() => {
		if (!cardNumber) {
			setCardType("");
			return;
		}

		if (cardTypeInfo[0] && cardTypeInfo[0].type in CARD_TYPE_LOGO_MAP) {
			setCardType(cardTypeInfo[0].type);
		}
	}, [cardNumber, cardTypeInfo]);

	// Clear errored state if card details change
	useEffect(() => {
		setErrored(false);
	}, [cardNumber, expirationDate, cvc]);

	const getInputClassName = (
		inputType: "cardNumber" | "cardHolderName" | "expirationDate" | "cvc",
		validFormat: boolean
	) => {
		const { isValid } = validityInfo[inputType];
		if ((!isValid && validFormat) || showInvalidState[inputType])
			return "pay-invoice-input-invalid";

		return "pay-invoice-input";
	};

	const onCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/\D/g, "");
		const chars = value.split("");
		const validationInfo = validateCard.number(value);

		let formatted = "";

		for (let i = 0; i < chars.length; i++) {
			if (validationInfo.card) {
				// `gaps` is an array of indices where spaces should be inserted
				const { gaps } = validationInfo.card;
				if (gaps.includes(i)) {
					formatted += " ";
				}
			}
			formatted += chars[i];
		}

		setCardNumebr(formatted);
	};

	const onExpirationDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/[^0-9\/]/g, "");
		if (value.length < expirationDate.length) {
			setExpirationDate(value);
			return;
		}
		if (value.length >= 3 && value[2] !== "/") {
			setExpirationDate(value.slice(0, 2) + "/" + value.slice(2));
			return;
		}

		if (value.length === 2 && value[0] > "1") {
			if (value[1] === "/") {
				setExpirationDate("0" + value[0] + "/");
			} else {
				setExpirationDate("0" + value[0] + "/" + value[1]);
			}
			return;
		}

		setExpirationDate(value);
	};

	const onSubmit: FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault();
		try {
			setSubmitting(true);
			setErrored(false);
			const card_number = cardNumber.replace(/\s/g, "");
			const card_exp_year = "20" + expirationDate.split("/")[1];
			const card_exp_month = expirationDate.split("/")[0];
			const card_cvc = cvc;
			setShowInvalidState(DEFAULT_INVALID_STATE);
			await onFormSubmit({
				card_number,
				card_cvc,
				card_exp_month,
				card_exp_year,
				conciergeAddOnSelected,
				extraAmount: conciergeAddOnSelected
					? CONCIERGE_ADD_ON_PRICE
					: undefined,
			});
		} catch (err) {
			setErrored(true);
			if (axios.isAxiosError(err)) {
				if (err.response?.data.error) {
				}
			}
		} finally {
			setSubmitting(false);
		}
	};

	const createInvoiceFieldFocusAndBlurProps = useCallback(
		(fieldName: keyof typeof validityInfo) => {
			const hasLength = (fieldName: keyof typeof validityInfo): boolean => {
				switch (fieldName) {
					case "cardNumber":
						return !!cardNumber.length;
					case "expirationDate":
						return !!expirationDate.length;
					case "cvc":
						return !!cvc.length;
					case "cardHolderName":
						return !!cardHolderName.length;
				}
			};
			return {
				onFocus: () => setShowInvalidState(p => ({ ...p, [fieldName]: false })),
				onBlur: () => {
					if (!validityInfo[fieldName].isValid && hasLength(fieldName)) {
						setShowInvalidState(p => ({ ...p, [fieldName]: true }));
						trackFieldValidation(fieldName);
					}
				},
			};
		},
		[
			cardNumber.length,
			expirationDate.length,
			cvc.length,
			cardHolderName.length,
			validityInfo,
		]
	);

	return (
		<div id="pay-invoice-form-card">
			{submitting && (
				<div id="pay-invoice-animation-container">
					<Image
						src={PayingInvoiceAnimation}
						alt="Paying invoice animation"
						id="pay-invoice-animation"
					/>
				</div>
			)}
			<p className="pay-card-heading">
				<b>Pay with Card</b>
			</p>
			{errored && (
				<div id="pay-invoice-form-payment-failed">
					<div id="pay-invoice-form-payment-failed-heading">
						<InvalidExclamationIcon fill="#fff" />
						<p className="sm">
							<b>Sorry, your payment could not be processed.</b>
						</p>
					</div>
					<p className="sm">
						Please check your card information and try again. If the problem
						persists, please try another card.
					</p>
				</div>
			)}
			<form id="pay-invoice-form" onSubmit={onSubmit}>
				<InvoiceTextField
					label="Card Holder Name"
					required
					placeholder="Your Name"
					value={cardHolderName}
					onChange={e => setCardHolderName(e.target.value)}
					className={getInputClassName("cardHolderName", !!cardHolderName)}
					invalid={showInvalidState.cardHolderName}
					autoComplete="cc-name"
					{...createInvoiceFieldFocusAndBlurProps("cardHolderName")}
				/>
				<div>
					<Label>Card Number*</Label>
					<div id="pay-invoice-cc-field">
						<InvoiceTextField
							required
							placeholder="1234 1234 1234 1234"
							maxLength={cardNumberFieldMaxLength}
							value={cardNumber}
							onChange={onCardNumberChange}
							containerClassName="mt-1"
							invalid={showInvalidState.cardNumber}
							className={getInputClassName(
								"cardNumber",
								cardTypeInfo[0]?.lengths.includes(cardNumber.length)
							)}
							autoComplete="cc-number"
							{...createInvoiceFieldFocusAndBlurProps("cardNumber")}
						/>
						<div id="pay-invoice-cc-logo">
							<CardLogo type={cardType} />
						</div>
					</div>
				</div>
				<div id="pay-invoice-form-field-row">
					<InvoiceTextField
						required
						label="Expiration Date"
						placeholder="MM/YY"
						maxLength={5}
						wrapperClassName="pay-invoice-form-field-half"
						invalid={showInvalidState.expirationDate}
						className={getInputClassName(
							"expirationDate",
							expirationDate.length === 5
						)}
						value={expirationDate}
						onChange={onExpirationDateChange}
						autoComplete="cc-exp"
						{...createInvoiceFieldFocusAndBlurProps("expirationDate")}
					/>
					<InvoiceTextField
						label="CVC"
						required
						numeric
						maxLength={cardTypeInfo[0]?.code.size ?? 3}
						placeholder={
							cardNumber && cardTypeInfo[0]?.code.size
								? Array(cardTypeInfo[0]?.code.size).fill("*").join("")
								: "***"
						}
						wrapperClassName="pay-invoice-form-field-half"
						className={getInputClassName(
							"cvc",
							cvc.length === (cardTypeInfo[0]?.code.size ?? 3)
						)}
						value={cvc}
						onChange={e => setCvc(e.target.value)}
						invalid={showInvalidState.cvc}
						autoComplete="cc-csc"
						{...createInvoiceFieldFocusAndBlurProps("cvc")}
					/>
				</div>
				{showConciergeAddOn && (
					<ConciergeAddOn
						onConciergeAddOnSelected={selected =>
							setConciergeAddOnSelected(selected)
						}
					/>
				)}
				<div id="pay-invoice-form-btn-container">
					<ButtonV2
						size="large"
						id={"pay-invoice-form-btn" + (submitting ? "-submitting" : "")}
						disabled={
							!Object.values(validityInfo).every(({ isValid }) => isValid) ||
							submitting
						}>
						{submitting ? (
							"Sending Payment..."
						) : (
							<>
								Pay{" "}
								{amount ? (
									<span className="no-translate">
										{formatDollar(
											parseFloat(amount) +
												(conciergeAddOnSelected ? CONCIERGE_ADD_ON_PRICE : 0),
											{
												withCents: "always",
											}
										)}
									</span>
								) : (
									"Invoice"
								)}
							</>
						)}
					</ButtonV2>
					<PoweredByStripe className="powered-by-stripe" />
				</div>
			</form>
		</div>
	);
};

export default PayInvoicePaymentForm;
