import { useRouter } from "next/navigation";
import { FC, useEffect } from "react";

interface RedirectProps {
	to: string;
	options?: Parameters<ReturnType<typeof useRouter>["push"]>[1];
}

export const Redirect: FC<RedirectProps> = props => {
	const { to, options } = props;

	const router = useRouter();

	useEffect(() => {
		router.push(to, options);
	}, [to, options]);

	return null;
};
