import ConciergeHeader from "public/account/survey/concierge/concierge-header.svg";
import CircleCheck from "public/account/survey/concierge/circle-check.svg";
import Radio from "./Input/Radio";
import { useState } from "react";
import ConciergeBenefitsModal from "./ConciergeBenefitsModal";
import ConciergeAddOnBG from "public/account/survey/concierge/concierge-invoice-bg.png";
import Image from "next/image";
const ConciergeAddOn = ({
	onConciergeAddOnSelected,
}: {
	onConciergeAddOnSelected: (selected: boolean) => void;
}) => {
	const [showModal, setShowModal] = useState(false);

	return (
		<div id="concierge-add-on">
			<p className="large bold denim">Protect My Exemption with Concierge!</p>
			<p className="denim_5 sm mt-0_5">
				Never miss out on savings or caught off guard.
			</p>
			<div className="concierge-add-on-container mt-4">
				<div className="concierge-add-on-bg-wrapper">
					<Image src={ConciergeAddOnBG} alt="Concierge Add On Background" />
				</div>
				<p className="extra-small kelly-dark semibold recommended-tag">
					Recommended
				</p>
				<ConciergeHeader className="concierge-icon" />
				<p className="large bold denim">
					Add Exemption Fall Off Protection. We will:
				</p>
				<div className="concierge-feature-list mt-1">
					<p className="concierge-feature-list-item">
						<span className="icon">
							<CircleCheck />
						</span>
						<span className="sm">Check your exemption status daily</span>
					</p>
					<p className="concierge-feature-list-item">
						<span className="icon">
							<CircleCheck />
						</span>
						<span className="sm">Alert you if your exemption falls off</span>
					</p>
					<p className="concierge-feature-list-item">
						<span className="icon">
							<CircleCheck />
						</span>
						<span className="sm">Reinstate your exemption on your behalf</span>
					</p>
				</div>
				<p
					className="semibold azure sm mt-2 text-right cursor-pointer relative z-10"
					onClick={() => setShowModal(true)}>
					View all Concierge benefits +
				</p>
			</div>
			<div className="concierge-add-on-footer mt-2">
				<div className="concierge-add-on-footer-radio-container">
					<Radio
						onChange={() => {
							onConciergeAddOnSelected(true);
						}}
						label="Yes, I want exemption fall off protection."
						name="concierge-add-on"
						smallLabel={true}
					/>
					<p className="small kelly-dark nowrap">+$99 /year</p>
				</div>
				<Radio
					onChange={() => {
						onConciergeAddOnSelected(false);
					}}
					label="No, I don't need exemption fall off protection."
					name="concierge-add-on"
					smallLabel={true}
				/>
			</div>

			{showModal && (
				<ConciergeBenefitsModal onAttemptExit={() => setShowModal(false)} />
			)}
		</div>
	);
};

export default ConciergeAddOn;
