import React from "react";
import PropertyModal from "../Modal";

interface HelpTextModalProps {
	helpText: string;
	label: string;
	onClose: () => void;
}

export const HelpTextModal: React.FC<HelpTextModalProps> = ({
	helpText,
	label,
	onClose,
}) => {
	return (
		<PropertyModal
			onAttemptExit={onClose}
			modalId="help-text-modal"
			containerId="help-text-modal-container"
			clickAway={true}>
			<div className="help-text-content">
				<p className="help-text-title large semibold text-center">{label}</p>
				<p className="help-text">{helpText}</p>
			</div>
		</PropertyModal>
	);
};

export default HelpTextModal;
