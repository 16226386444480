import React, { useEffect, useState } from "react";
import { usePropertyContext } from "../../context";
import ConciergeGuy from "public/account/survey/concierge/concierge-guy.svg";
import ConciergeGuyTallSVG from "public/concierge-guy-tall.svg";
import { SavingsBox } from "../../Concierge/ConciergeOptionCard";
import { ConciergeFeatures } from "../../Concierge/ConciergeOptionCard";
import { ConciergeProFeatures } from "../../Concierge/ConciergeProOptionCard";
import PayInvoicePaymentForm from "components/pages/PayInvoice/PayInvoicePaymentForm";
import client from "utils/client";
import ButtonV2 from "elements/ButtonV2";
import Image from "next/image";
import PaymentSuccessfulIcon from "public/invoice-paid.gif";
import { useResponsiveContext } from "context/ResponsiveContext";
import Warning from "public/account/survey/concierge/warning.svg";
import CircleCheck from "public/account/survey/concierge/check-circle-green.svg";
import PropertyModal from "../../Modal";
import { usePathname, useRouter } from "next/navigation";
import { useAuthContext } from "context/AuthContext";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import {
	DEFAULT_CONTINGENCY_PERCENTAGE,
	useConciergeContext,
} from "../../Concierge/ConciergePage";
import { Tooltip3 } from "elements/Tooltip";
import { ConciergeOptionCard } from "../../Concierge/ConciergeOptionCard";
import { ConciergeOptionSlider } from "../../Concierge/ConciergeOptionCard";

export const ConciergePaymentSuccess = ({
	onContinue,
}: {
	onContinue: () => void;
}) => {
	return (
		<div className="payment-success-card">
			<div id="pay-invoice-payment-successful-animation-container">
				<Image
					id="pay-invoice-payment-successful-animation"
					src={PaymentSuccessfulIcon}
					alt="Invoice Paid Animation"
				/>
			</div>
			<p>
				<b>Payment Received!</b>
			</p>
			<p className="sm mt-1">We will send a receipt to the account email.</p>
			<ButtonV2
				variant="primary"
				size="extra-small"
				className="w-100 next-btn"
				onClick={onContinue}>
				Continue
			</ButtonV2>
		</div>
	);
};

export const ConciergePlanDetails = () => {
	const { details } = usePropertyContext();
	const { isMobile } = useResponsiveContext();
	return (
		<div className="concierge-options-cards">
			<ConciergeOptionCard
				contingencyPercentage={
					details?.contingency
						? details?.contingency
						: DEFAULT_CONTINGENCY_PERCENTAGE
				}
				onOptionSelect={() => {}}
				hideCTA={true}
				isExpandedByDefault={isMobile ? false : true}>
				<ConciergeOptionSlider />
			</ConciergeOptionCard>
		</div>
	);
};

export const ConciergePaymentForm = ({
	onPaymentConfirmed,
}: {
	onPaymentConfirmed?: () => void;
}) => {
	const {
		paymentConfirmed,
		paymentErrored,
		setPaymentErrored,
		submitPayment,
		planPrice,
	} = useConciergeContext();

	React.useEffect(() => {
		if (paymentConfirmed && onPaymentConfirmed) {
			onPaymentConfirmed();
		}
	}, [paymentConfirmed]);

	return (
		<PayInvoicePaymentForm
			errored={paymentErrored}
			setErrored={setPaymentErrored}
			trackFieldValidation={(fieldName: string) => {}}
			onFormSubmit={submitPayment}
			amount={planPrice.toString()}
		/>
	);
};

const ConciergePayment: React.FC<{
	handleContinueClick?: () => void;
	showWarningBanner?: boolean;
	defaultExpandedFlag?: boolean;
}> = ({ handleContinueClick, showWarningBanner, defaultExpandedFlag }) => {
	const {
		paymentConfirmed,
		downgradeInterestedTier,
		downgrading,
		downgradingConfirmed,
		downgradingErrored,
	} = useConciergeContext();

	const { userData } = useAuthContext();
	const { details } = usePropertyContext();
	const trackEvent = useEventTracker();
	const router = useRouter();
	const pathname = usePathname();

	const [cancelModalOpen, setCancelModalOpen] = useState(false);

	useEffect(() => {
		if (pathname.includes("/concierge")) {
			trackEvent({
				eventName: constants.EVENTS.Concierge_upgrade_payment_viewed,
				data: {
					CustomerId: userData?.id,
				},
			});
		} else {
			trackEvent({
				eventName: constants.EVENTS.Concierge_onboarding_upgrade_payment_viewed,
				data: {
					CustomerId: userData?.id,
				},
			});
		}
	}, []);

	const showOverviewPage = () => {
		router.push(`/account/properties/${details?.id}`);
	};

	const handleContinue = () => {
		if (handleContinueClick) {
			handleContinueClick();
		} else {
			trackEvent({
				eventName: constants.EVENTS.Concierge_onboarding_upgrade_payment_action,
				data: {
					CustomerId: userData?.id,
					action: "submit",
				},
			});
		}
	};

	const handleDowngradingInterestedTier = async () => {
		if (pathname.includes("/concierge")) {
			setCancelModalOpen(true);
		} else {
			await downgradeInterestedTier();
		}
	};

	return (
		<div className="concierge-payment-wrapper">
			{paymentConfirmed ? (
				<ConciergePaymentSuccess onContinue={handleContinue} />
			) : (
				<>
					<ConciergePlanDetails />
					<div className="payment-card-wrapper">
						{showWarningBanner && (
							<div className="warning-banner-wrapper">
								<p className="warning-banner-header">
									<Warning />
									<span className="semibold royal">
										Your upgrade to Concierge is not complete.
									</span>
								</p>
								<p className="warning-banner-body small royal">
									Enter your card detail to activate your Concierge
									subscription, or if you'd like to cancel your upgrade you can
									click "I don't want concierge" below.
								</p>
							</div>
						)}
						<div className="payment-card-container">
							<ConciergePaymentForm />
						</div>
						<div className="btn-container">
							{downgrading ? (
								<p className="cancel-subscription sm">Cancelling...</p>
							) : (
								<p
									className="cancel-subscription sm"
									onClick={handleDowngradingInterestedTier}>
									I don't want concierge
								</p>
							)}
						</div>
					</div>
					{cancelModalOpen && (
						<PropertyModal onAttemptExit={() => setCancelModalOpen(false)}>
							<div className="cancel-subsciption-modal-wrapper">
								<div className="confirmation-modal-wrapper">
									{downgradingConfirmed ? (
										<>
											<div className="warning-img-wrapper">
												<CircleCheck />
											</div>
											<p className="xl bold">Membership Canceled</p>
											<ButtonV2
												size="small"
												variant="primary"
												className="close-btn"
												onClick={showOverviewPage}>
												Close
											</ButtonV2>
										</>
									) : (
										<>
											<div className="warning-img-wrapper">
												<Warning />
											</div>
											<p className="xl bold">Cancel Concierge Services?</p>
											<p className="sm text-center">
												You will lose access to Concierge features, services and
												support. If we've achieved successful savings already
												from a service with a contingecy fee such as appeals,
												exemption refunds or bill reductions, you will still be
												expected to pay those invoices.
											</p>
											{downgradingErrored && (
												<p className="rust">
													There was an error cancelling your subscription.
													Please try again.
												</p>
											)}
											<div className="btn-container">
												<ButtonV2
													size="small"
													variant="destructive-outline"
													onClick={downgradeInterestedTier}
													disabled={downgrading}>
													{downgrading
														? "Cancelling..."
														: "Yes, Cancel Concierge"}
												</ButtonV2>
												<ButtonV2
													size="small"
													variant="primary-outline"
													onClick={() => setCancelModalOpen(false)}>
													No, Keep Finding Savings
												</ButtonV2>
											</div>
										</>
									)}
								</div>
							</div>
						</PropertyModal>
					)}
				</>
			)}
		</div>
	);
};

export default ConciergePayment;
