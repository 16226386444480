import { TextField, Image } from "components";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import client from "utils/client";
import {
	PropertySurveyPhotoUploadPrompt,
	usePropertyContext,
} from "../../context";
import QuestionCard from "components/elements/QuestionCard";
import { getMostRecentAppeal } from "utils/getMostRecentAppeal";
import { PropertySurveyEvidenceModal } from "../PropertySurveyEvidenceModal";
import RoofReplacementExample1 from "public/evidence-examples/roof-replacement1.png";
import RoofReplacementExample2 from "public/evidence-examples/roof-replacement2.png";
import RoofReplacementExample3 from "public/evidence-examples/roof-replacement3.png";
import RoofReplacementExample4 from "public/evidence-examples/roof-replacement4.png";
import formatDollar, { formatDollarStr } from "utils/formatDollar";
import TextArea from "elements/Input/TextArea";
import formatDate from "utils/formatDate";
import { isValidDate } from "utils/validDate";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ExitAreYouSure from "components/pages/Account/shared/ExitAreYouSure";
import TimeForward from "public/time-forward.svg";
import { FileData } from "utils/types";
import { FileUploadType, splitFileUploads } from "elements/Input/FileUpload";
import ButtonV2 from "elements/ButtonV2";

const RoofReplacement = () => {
	const {
		details,
		activeQuestionId,
		send,
		next,
		propertySurveyContext,
		allUploadedPropertySurveyFiles,
	} = usePropertyContext();

	const [filesToUpload, setFilesToUpload] = useState<FileUploadType[]>([]);
	const [roofDamageCost, setRoofDamageCost] = useState(
		propertySurveyContext?.q_roof_damage_cost
			? formatDollar(propertySurveyContext?.q_roof_damage_cost)
			: ""
	);
	const [roofDamageDescription, setRoofDamageDescription] = useState(
		propertySurveyContext?.q_roof_damage_desc || ""
	);
	const [roofDamageDate, setRoofDamageDate] = useState(
		propertySurveyContext?.q_roof_damage_date || ""
	);
	const [skipModalOpen, setSkipModalOpen] = useState(false);
	const [evidenceModalOpen, setEvidenceModalOpen] = useState(false);
	const [hasEvidenceModalBeenOpened, setHasEvidenceModalBeenOpened] =
		useState(false);

	const questionFiles = useMemo(() => {
		return allUploadedPropertySurveyFiles.filter(fileName =>
			fileName.startsWith("roofDamage")
		);
	}, []); // We only want to derive this value on mount

	const filesUploaded = useMemo(() => {
		return questionFiles.length > 0 || filesToUpload.length > 0;
	}, [questionFiles, filesToUpload]);

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSubmit = async () => {
		send({
			type: "update_context",
			context: {
				q_roof_damage: 1,
				q_roof_damage_cost: Number(roofDamageCost.replace(/\D/g, "").trim()),
				q_roof_damage_desc: roofDamageDescription,
				q_roof_damage_date: roofDamageDate,
			},
		});

		try {
			setSubmitting(true);
			setError(false);

			await client.submitQuestionAnswer({
				questionId: activeQuestionId,
				appealId: details!.appeal_id,
				payload: {
					value: true,
					desc: roofDamageDescription || undefined,
					date: roofDamageDate || undefined,
					cost: roofDamageCost.replace(/\D/g, "").trim() || undefined,
				},
			});

			if (filesToUpload.length) {
				const { errors, success } = await splitFileUploads({
					filesToUpload,
					updateFilesToUpload: setFilesToUpload,
					buildRequest: async fileToUpload => {
						const fd = new FormData();
						fd.append("roofDamage", fileToUpload.file);
						await client.uploadFile({
							form: fd,
							appealId: details!.appeal_id.toString(),
							onProgress: pe => {
								setFilesToUpload(prev =>
									prev.map(fu => {
										if (fu.file !== fileToUpload.file) return fu;
										return {
											...fu,
											progress: pe,
										};
									})
								);
							},
						});
					},
				});

				send({
					type: "update_context",
					context: {
						files: [
							...propertySurveyContext.files,
							...success.map(fileToUpload => {
								const fileData: FileData = {
									file_name: "roofDamage-" + fileToUpload.file.name,
									question: "roofDamage",
									is_ownwell_document: 0,
								};
								return fileData;
							}),
						],
					},
				});

				if (errors.length > 0) {
					setError(true);
					return;
				}
			}

			if (!filesUploaded) {
				setSkipModalOpen(true);
			} else {
				next();
			}
		} catch (e) {
			setError(true);
			console.error(e);
		} finally {
			setSubmitting(false);
		}
	};

	const btnDisabled = useMemo(() => {
		const dateIsValid =
			roofDamageDate.length === 0 || roofDamageDate.length === 10;
		return (
			submitting || !roofDamageDescription || !roofDamageCost || !dateIsValid
		);
	}, [submitting, roofDamageDescription, roofDamageCost, roofDamageDate]);

	const handleTooltipClick = () => {
		setEvidenceModalOpen(true);
		setHasEvidenceModalBeenOpened(true);
	};

	const handleSkip = () => {
		if (!filesUploaded) {
			setSkipModalOpen(true);
		} else {
			next();
		}
	};

	const lastYear = useMemo(
		() => (details?.tax_year ?? new Date().getFullYear()) - 1,
		[details]
	);

	const costUnder5000 = useMemo(() => {
		return (
			roofDamageCost !== "" && Number(roofDamageCost.replace(/\D/g, "")) < 5000
		);
	}, [roofDamageCost]);

	const showDocumentsRecommendedWarning = useMemo(() => {
		if (
			(roofDamageDescription !== "" ||
				roofDamageCost !== "" ||
				roofDamageDate !== "") &&
			!filesUploaded
		) {
			return true;
		}
		return false;
	}, [roofDamageDescription, roofDamageDate, roofDamageCost, filesUploaded]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				handleSubmit();
			}}>
			{evidenceModalOpen && <EvidenceModal setOpen={setEvidenceModalOpen} />}
			<QuestionCard
				id="roof-replacement"
				tooltipText="A structure's roof should be replaced every 25-30 years or if it has been damaged by a storm (wind, hail, ice, etc.) damage from a storm. Knowing when you last replaced your roof or if it has recently sustained damage will help us have all the information needed to argue for a larger tax reduction on your behalf."
				onTooltipClick={() => handleTooltipClick()}
				withFiles
				filesToUpload={filesToUpload}
				setFilesToUpload={setFilesToUpload}
				alreadyUploaded={questionFiles}
				question={<p className="lg bold">Roof damage</p>}
				subTitle={
					<p className="sm property-profile-question-card-subtitle denim-medium">
						Quotes, invoices, and insurance estimates are the most effective
						ways to get a property tax reduction for needed roof replacements or
						repairs.
						<br />
						<b>
							The assessor’s office rarely grants a tax reduction without
							documentation showing the dollar value of repairs needed.
						</b>
					</p>
				}>
				<div className="property-survey-question-section-seperator" />
				<p className="bold">Describe the roof damage or condition.*</p>
				<p className="sm denim-medium mb-1">
					Describe the condition of the roof including location of damage and
					impact it has had on your property.
				</p>
				<TextArea
					className="mb-2 w-100"
					value={roofDamageDescription}
					onChange={e => setRoofDamageDescription(e.target.value)}
					rows={4}
					required
					placeholder="Description of condition"
				/>
				<p className="bold">What is the estimated impact to property value?*</p>
				<p className="sm denim-medium mb-1">
					Upload repair estimates or insurance claims as support for this value.
					Values under $5,000 are unlikely to have an impact on the assessment
					value.
				</p>
				<TextField
					className={costUnder5000 ? "input-warning" : ""}
					numeric
					required
					placeholder="$"
					value={roofDamageCost}
					onChange={e =>
						setRoofDamageCost(formatDollarStr(e.target.value) ?? "")
					}
				/>
				{costUnder5000 && (
					<p className="sm terracotta">
						Values less than {formatDollarStr(5000)}, are rarely helpful in
						getting an assessment reduction.
					</p>
				)}
				<p className="bold mt-2">Date of photos</p>
				<p className="sm denim-medium mb-1">
					{PropertySurveyPhotoUploadPrompt}
				</p>
				<TextField
					maxLength={10}
					minLength={10}
					placeholder="MM/DD/YYYY"
					value={roofDamageDate || ""}
					onChange={e => {
						const formatted = formatDate(e, roofDamageDate || "");
						const valid = isValidDate(formatted);

						if (valid) {
							setRoofDamageDate(formatted);
						}
					}}
				/>
			</QuestionCard>
			{showDocumentsRecommendedWarning && (
				<div className="property-profile-question-note-rust">
					<p className="sm">
						We recommend uploading supporting documentation before continuing.
						Evidence submitted without documentation rarely results in a
						reduction.
					</p>
				</div>
			)}
			<ButtonV2
				size="large"
				disabled={btnDisabled}
				type="submit"
				className="mx-auto">
				{submitting ? "Submitting..." : "Continue"}
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
			{skipModalOpen && (
				<ExitAreYouSure
					top="40%"
					label="Information Requested"
					body={
						<div style={{ marginTop: "8px" }}>
							<p>
								Evidence submitted without documentation rarely results in a
								reduction.
							</p>

							<br />
							<p>
								Please provide supporting documentation now or at a later time
								via your property portal or email.
							</p>
						</div>
					}
					exitBtnContent={
						<>
							<TimeForward />
							<p>Add Info Later</p>
						</>
					}
					onExit={() => {
						setSkipModalOpen(false);
						next(true);
					}}
					onCancel={() => {
						setSkipModalOpen(false);
					}}
				/>
			)}
		</form>
	);
};

export const EvidenceModal = (props: {
	setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
	const { setOpen } = props;

	return (
		<PropertySurveyEvidenceModal onAttemptExit={() => setOpen(false)}>
			<p>
				<b>Evidence of Roof Condition</b>
			</p>
			<div className="property-survey-evidence-modal-body-text-container">
				<p className="sm">
					<b>
						Quotes, invoices, and insurance estimates for needed roof
						replacements or repairs are one of the most effective ways to get a
						property tax reduction.
					</b>{" "}
					If your structure’s roof is in poor condition or damaged, please
					provide as much evidence and documentation of the issues as possible.
					<br />
					<br />
					Photos and written descriptions are helpful. However, the assessor’s
					office rarely grants a tax reduction without documentation showing the
					dollar value of repairs needed.
				</p>
			</div>
			<p>Example Documents & Photos</p>
			<div className="property-survey-evidence-modal-image-container-grid">
				<Image
					// openInNewTab
					placeholder="blur"
					src={RoofReplacementExample3}
					alt="Roof replacement document example image"
					quality={100}
				/>
				<Image
					// openInNewTab
					placeholder="blur"
					src={RoofReplacementExample4}
					quality={100}
					alt="Roof damange example image"
				/>
				<Image
					// openInNewTab
					placeholder="blur"
					src={RoofReplacementExample2}
					alt="Second roof damage example image"
					quality={100}
				/>
				<Image
					// openInNewTab
					placeholder="blur"
					src={RoofReplacementExample1}
					alt="Third roof damage example image"
					quality={100}
				/>
			</div>
			<ButtonV2
				size="mobile-extra-small"
				onClick={() => setOpen(false)}
				className="mx-auto">
				Back to Question
			</ButtonV2>
		</PropertySurveyEvidenceModal>
	);
};

export default RoofReplacement;
