import { Checkbox, TextField, Image } from "components";
import TextArea from "elements/Input/TextArea";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import client from "utils/client";
import {
	PropertySurveyPhotoUploadPrompt,
	usePropertyContext,
} from "../../context";
import { yearOptions } from "../../options";
import QuestionCard from "components/elements/QuestionCard";
import { getMostRecentAppeal } from "utils/getMostRecentAppeal";
import FloodingExample1 from "public/evidence-examples/flooding1.png";
import FloodingExample2 from "public/evidence-examples/flooding2.png";
import FloodingExample3 from "public/evidence-examples/flooding3.png";
import FloodingExample4 from "public/evidence-examples/flooding4.png";
import { PropertySurveyEvidenceModal } from "../PropertySurveyEvidenceModal";
import formatDollar, { formatDollarStr } from "utils/formatDollar";
import { getLastYearAppeal } from "utils/getLastYearAppeal";
import { useEventTracker_DEPRECATED } from "utils/useEventTracker";
import constants from "utils/constants";
import formatDate from "utils/formatDate";
import { isValidDate } from "utils/validDate";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ExitAreYouSure from "components/pages/Account/shared/ExitAreYouSure";
import TimeForward from "public/time-forward.svg";
import { FileData } from "utils/types";
import { FileUploadType, splitFileUploads } from "elements/Input/FileUpload";
import ButtonV2 from "elements/ButtonV2";

const FLOODING_LIST_OPTIONS = [
	"Natural disaster",
	"Plumbing issues",
	"Property drainage issues",
];

const LIST_SEPERATOR = ", ";

const Flooding = () => {
	const {
		details,
		activeQuestionId,
		send,
		next,
		propertySurveyContext,
		allUploadedPropertySurveyFiles,
	} = usePropertyContext();

	const [filesToUpload, setFilesToUpload] = useState<FileUploadType[]>([]);
	const [floodingCauseList, setFloodingCauseList] = useState<string[]>(
		propertySurveyContext?.q_flooded_cause?.split(",") || []
	);
	const [floodingCost, setFloodingCost] = useState(
		propertySurveyContext?.q_flooded_cost
			? formatDollar(propertySurveyContext?.q_flooded_cost)
			: ""
	);
	const [floodingDescription, setFloodingDescription] = useState(
		propertySurveyContext?.q_flooded_desc || ""
	);
	const [floodingDate, setFloodingDate] = useState(
		propertySurveyContext?.q_flooded_date || ""
	);
	const [skipModalOpen, setSkipModalOpen] = useState(false);
	const [evidenceModalOpen, setEvidenceModalOpen] = useState(false);
	const [hasEvidenceModalBeenOpened, setHasEvidenceModalBeenOpened] =
		useState(false);

	const questionFiles = useMemo(() => {
		return allUploadedPropertySurveyFiles.filter(fileName =>
			fileName.startsWith("flooding")
		);
	}, []); // We only want to derive this value on mount

	const filesUploaded = useMemo(() => {
		return questionFiles.length > 0 || filesToUpload.length > 0;
	}, [questionFiles, filesToUpload]);

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSubmit = async () => {
		send({
			type: "update_context",
			context: {
				q_flooded: 1,
				q_flooded_cause: floodingCauseList.join(LIST_SEPERATOR),
				q_flooded_cost: Number(floodingCost.replace(/\D/g, "").trim()),
				q_flooded_desc: floodingDescription,
				q_flooded_date: floodingDate,
			},
		});

		try {
			setSubmitting(true);
			setError(false);

			await client.submitQuestionAnswer({
				questionId: activeQuestionId,
				appealId: details!.appeal_id,
				payload: {
					value: true,
					desc: floodingDescription || undefined,
					date: floodingDate || undefined,
					cost: floodingCost.replace(/\D/g, "").trim() || undefined,
					cause: floodingCauseList.join(LIST_SEPERATOR) || undefined,
				},
			});

			if (filesToUpload.length) {
				const { errors, success } = await splitFileUploads({
					filesToUpload,
					updateFilesToUpload: setFilesToUpload,
					buildRequest: async fileToUpload => {
						const fd = new FormData();
						fd.append("flooding", fileToUpload.file);
						await client.uploadFile({
							form: fd,
							appealId: details!.appeal_id.toString(),
							onProgress: pe => {
								setFilesToUpload(prev =>
									prev.map(fu => {
										if (fu.file !== fileToUpload.file) return fu;
										return {
											...fu,
											progress: pe,
										};
									})
								);
							},
						});
					},
				});

				send({
					type: "update_context",
					context: {
						files: [
							...propertySurveyContext.files,
							...success.map(fileToUpload => {
								const fileData: FileData = {
									file_name: "flooding-" + fileToUpload.file.name,
									question: "flooding",
									is_ownwell_document: 0,
								};
								return fileData;
							}),
						],
					},
				});

				if (errors.length > 0) {
					setError(true);
					return;
				}
			}

			if (!filesUploaded) {
				setSkipModalOpen(true);
			} else {
				next();
			}
		} catch (e) {
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const btnDisabled = useMemo(() => {
		const dateIsValid = floodingDate.length === 0 || floodingDate.length === 10;
		return (
			submitting ||
			!floodingCauseList.length ||
			!floodingDescription ||
			!floodingCost ||
			!dateIsValid
		);
	}, [
		submitting,
		floodingCauseList,
		floodingDescription,
		floodingCost,
		floodingDate,
	]);

	const handleTooltipClick = () => {
		setHasEvidenceModalBeenOpened(true);
		setEvidenceModalOpen(true);
	};

	const handleSkip = () => {
		if (!filesUploaded) {
			setSkipModalOpen(true);
		} else {
			next();
		}
	};

	const lastYear = useMemo(
		() => (details?.tax_year ?? new Date().getFullYear()) - 1,
		[details]
	);

	const costUnder5000 = useMemo(() => {
		return (
			floodingCost !== "" && Number(floodingCost.replace(/\D/g, "")) < 5000
		);
	}, [floodingCost]);

	const showDocumentsRecommendedWarning = useMemo(() => {
		if (
			(floodingCauseList.length > 0 ||
				floodingDescription !== "" ||
				floodingCost !== "" ||
				floodingDate !== "") &&
			!filesUploaded
		) {
			return true;
		}
		return false;
	}, [
		floodingCauseList,
		floodingDescription,
		floodingDate,
		floodingCost,
		filesUploaded,
	]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			{evidenceModalOpen && <EvidenceModal setOpen={setEvidenceModalOpen} />}
			<QuestionCard
				id="flooding"
				tooltipText="If a property has ever flooded and sustained water damage, we can use evidence and anecdotes surrounding the flood to argue for a larger tax reduction on your behalf."
				onTooltipClick={() => handleTooltipClick()}
				filesLabel="Quotes, Invoices, and/or Photos showing proof of flood damage"
				withFiles
				alreadyUploaded={questionFiles}
				filesToUpload={filesToUpload}
				setFilesToUpload={setFilesToUpload}
				withRadio={false}
				question={<p className="lg bold">Recent flooding</p>}
				subTitle={
					<p className="sm property-profile-question-card-subtitle denim-medium">
						Quotes, invoices, and insurance estimates are the most effective
						ways to get a property tax reduction for flooding and water damage.
						<br />
						<b>
							The assessor’s office rarely grants a tax reduction without
							documentation showing the dollar value of repairs needed.
						</b>
					</p>
				}>
				<div className="property-survey-question-section-seperator" />
				<div className="property-survey-one-column-checkbox-container">
					{FLOODING_LIST_OPTIONS.map(name => (
						<Checkbox
							key={name}
							name="flooding-cause"
							label={name}
							checked={floodingCauseList.includes(name)}
							onChange={e => {
								setFloodingCauseList(el =>
									el.includes(name)
										? el.filter(e => e !== name)
										: el.concat(name)
								);
							}}
							labelEl="p"
						/>
					))}
				</div>
				<div className="property-survey-question-section-seperator" />
				<p className="bold">Describe the water damage.*</p>
				<p className="sm denim-medium mb-1">
					Describe how the flooding has impacted your home including location
					and any damage that remains because of the flood.
				</p>
				<TextArea
					className="mb-2 w-100"
					value={floodingDescription}
					onChange={e => setFloodingDescription(e.target.value)}
					rows={4}
					required
					placeholder="Description of damage"
				/>
				<p className="bold">What is the estimated impact to property value?*</p>
				<p className="sm denim-medium mb-1">
					Upload repair estimates or insurance claims as support for this value.
					Values under $5,000 are unlikely to have an impact on the assessment
					value.
				</p>
				<TextField
					className={costUnder5000 ? "input-warning" : ""}
					numeric
					required
					placeholder="$"
					value={floodingCost}
					onChange={e => setFloodingCost(formatDollarStr(e.target.value) ?? "")}
				/>
				{costUnder5000 && (
					<p className="sm terracotta">
						Values less than {formatDollarStr(5000)}, are rarely helpful in
						getting an assessment reduction.
					</p>
				)}
				<p className="bold mt-2">Date of photos</p>
				<p className="sm denim-medium mb-1">
					{PropertySurveyPhotoUploadPrompt}
				</p>
				<TextField
					maxLength={10}
					minLength={10}
					placeholder="MM/DD/YYYY"
					value={floodingDate || ""}
					onChange={e => {
						const formatted = formatDate(e, floodingDate || "");
						const valid = isValidDate(formatted);

						if (valid) {
							setFloodingDate(formatted);
						}
					}}
				/>
			</QuestionCard>
			{showDocumentsRecommendedWarning && (
				<div className="property-profile-question-note-rust">
					<p className="sm">
						We recommend uploading supporting documentation before continuing.
						Evidence submitted without documentation rarely results in a
						reduction.
					</p>
				</div>
			)}
			<ButtonV2
				size="large"
				disabled={btnDisabled}
				type="submit"
				className="mx-auto">
				Continue
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
			{skipModalOpen && (
				<ExitAreYouSure
					top="40%"
					label="Information Requested"
					body={
						<div style={{ marginTop: "8px" }}>
							<p>
								Evidence submitted without documentation rarely results in a
								reduction.
							</p>

							<br />
							<p>
								Please provide supporting documentation now or at a later time
								via your property portal or email.
							</p>
						</div>
					}
					exitBtnContent={
						<>
							<TimeForward />
							<p>Add Info Later</p>
						</>
					}
					onExit={() => {
						setSkipModalOpen(false);
						next(true);
					}}
					onCancel={() => {
						setSkipModalOpen(false);
					}}
				/>
			)}
		</form>
	);
};

export const EvidenceModal = (props: {
	setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
	const { setOpen } = props;

	return (
		<PropertySurveyEvidenceModal onAttemptExit={() => setOpen(false)}>
			<p>
				<b>Evidence of Flooding</b>
			</p>
			<div className="property-survey-evidence-modal-body-text-container">
				<p className="sm">
					<b>
						Quotes, invoices, and insurance estimates for flooding and water
						damage are one of the most effective ways to get a property tax
						reduction.
					</b>{" "}
					If there is water damage on the property or consistent flooding
					issues, please provide as much evidence and documentation as possible.
					<br />
					<br />
					Photos and written descriptions are helpful. However, the assessor’s
					office rarely grants a tax reduction without documentation showing the
					dollar value of repairs needed.
				</p>
			</div>
			<p>Example Documents & Photos</p>
			<div className="property-survey-evidence-modal-image-container-grid">
				<Image
					// openInNewTab
					placeholder="blur"
					src={FloodingExample3}
					alt="Flooding documents example image"
					quality={100}
				/>
				<Image
					// openInNewTab
					placeholder="blur"
					src={FloodingExample4}
					alt="Flooding example damage image"
					quality={100}
				/>
				<Image
					// openInNewTab
					placeholder="blur"
					src={FloodingExample2}
					alt="Second flooding damage example image"
					quality={100}
				/>
				<Image
					// openInNewTab
					placeholder="blur"
					src={FloodingExample1}
					alt="Third flooding damage example image"
					quality={100}
				/>
			</div>
			<ButtonV2
				size="mobile-extra-small"
				onClick={() => setOpen(false)}
				className="mx-auto">
				Back to Question
			</ButtonV2>
		</PropertySurveyEvidenceModal>
	);
};

export default Flooding;
