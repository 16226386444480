import React from "react";
import PropertyModal from "components/pages/Account/Property/Modal";
import { ConciergeOptionsCards } from "components/pages/Account/Property/Concierge/ConciergePage";
import ConciergeHeaderBG from "public/account/survey/concierge/concierge-header-bg.png";
import ConciergeHeader from "public/account/survey/concierge/concierge-header.svg";
import Image from "next/image";

interface ConciergeBenefitsModalProps {
	onAttemptExit: () => void;
}

const ConciergeBenefitsModal: React.FC<ConciergeBenefitsModalProps> = ({
	onAttemptExit,
}) => {
	return (
		<PropertyModal
			onAttemptExit={onAttemptExit}
			modalId="concierge-benefits-modal"
			containerId="concierge-benefits-modal-container"
			clickAway={true}>
			<div className="concierge-benefits-modal-wrapper">
				<div className="concierge-header">
					<div className="nothern-light-gradient-wrapper">
						<Image src={ConciergeHeaderBG} alt="Concierge Header Background" />
					</div>
					<div className="concierge-header-img-wrapper">
						<ConciergeHeader className="concierge-header-img" />
					</div>
					<p className="fancy-subtitle h4">The No. 1 Homeowner Membership</p>
					<p className="semibold xs denim-dark text-center">
						Join the homeowners who save the most time and money! A Concierge
						membership is all you need.
					</p>
				</div>
				<div className="mb-3"></div>
				<ConciergeOptionsCards
					handleSkip={() => {}}
					onOptionSelect={() => {}}
					hideCta={true}
					showSlider={false}
				/>
			</div>
		</PropertyModal>
	);
};

export default ConciergeBenefitsModal;
