import { Checkbox, TextField, Image } from "components";
import TextArea from "elements/Input/TextArea";
import {
	ChangeEventHandler,
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useState,
} from "react";
import client from "utils/client";
import {
	PropertySurveyPhotoUploadPrompt,
	usePropertyContext,
} from "../../context";
import QuestionCard from "components/elements/QuestionCard";
import { getMostRecentAppeal } from "utils/getMostRecentAppeal";
import { PropertySurveyEvidenceModal } from "../PropertySurveyEvidenceModal";
import FoundationIssuesExample1 from "public/evidence-examples/foundation-issues1.png";
import FoundationIssuesExample2 from "public/evidence-examples/foundation-issues2.png";
import FoundationIssuesExample3 from "public/evidence-examples/foundation-issues3.png";
import FoundationIssuesExample4 from "public/evidence-examples/foundation-issues4.png";
import formatDollar, { formatDollarStr } from "utils/formatDollar";
import { getLastYearAppeal } from "utils/getLastYearAppeal";
import { useEventTracker_DEPRECATED } from "utils/useEventTracker";
import constants from "utils/constants";
import formatDate from "utils/formatDate";
import { isValidDate } from "utils/validDate";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import ExitAreYouSure from "components/pages/Account/shared/ExitAreYouSure";
import TimeForward from "public/time-forward.svg";
import { FileData } from "utils/types";
import { FileUploadType, splitFileUploads } from "elements/Input/FileUpload";
import ButtonV2 from "elements/ButtonV2";

const FOUDNATION_ISSUE_LIST_OPTIONS = [
	"Large cracks or gaps in foundation",
	"Wood rot or termites in the structure",
	"Other major structural issue",
];

const FoundationIssues = () => {
	const {
		details,
		activeQuestionId,
		send,
		next,
		propertySurveyContext,
		allUploadedPropertySurveyFiles,
	} = usePropertyContext();

	const [filesToUpload, setFilesToUpload] = useState<FileUploadType[]>([]);
	const [foundationIssueList, setFoundationIssueList] = useState<string[]>(
		propertySurveyContext?.q_structural_issues_list?.split(",") || []
	);
	const [foundationIssuesCost, setFoundationIssuesCost] = useState(
		propertySurveyContext?.q_structural_issues_cost
			? formatDollar(propertySurveyContext?.q_structural_issues_cost)
			: ""
	);
	const [foundationIssueDescription, setFoundationIssueDescription] = useState(
		propertySurveyContext?.q_structural_issues_desc || ""
	);
	const [foundationIssueDate, setFoundationIssueDate] = useState(
		propertySurveyContext?.q_structural_issues_date || ""
	);
	const [skipModalOpen, setSkipModalOpen] = useState(false);
	const [evidenceModalOpen, setEvidenceModalOpen] = useState(false);
	const [hasEvidenceModalBeenOpened, setHasEvidenceModalBeenOpened] =
		useState(false);

	const questionFiles = useMemo(() => {
		return allUploadedPropertySurveyFiles.filter(fileName =>
			fileName.startsWith("foundationIssues")
		);
	}, []); // We only want to derive this value on mount

	const filesUploaded = useMemo(() => {
		return questionFiles.length > 0 || filesToUpload.length > 0;
	}, [questionFiles, filesToUpload]);

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const handleSubmit = async () => {
		send({
			type: "update_context",
			context: {
				q_structural_issues: 1,
				q_structural_issues_list: foundationIssueList.join(","),
				q_structural_issues_cost: Number(
					foundationIssuesCost.replace(/\D/g, "").trim()
				),
				q_structural_issues_desc: foundationIssueDescription,
				q_structural_issues_date: foundationIssueDate,
			},
		});

		try {
			setSubmitting(true);
			setError(false);

			await client.submitQuestionAnswer({
				questionId: activeQuestionId,
				appealId: details!.appeal_id,
				payload: {
					value: true,
					desc: foundationIssueDescription || undefined,
					date: foundationIssueDate || undefined,
					cost: foundationIssuesCost.replace(/\D/g, "").trim() || undefined,
					list: foundationIssueList.join(","),
				},
			});

			if (filesToUpload.length) {
				const { errors, success } = await splitFileUploads({
					filesToUpload,
					updateFilesToUpload: setFilesToUpload,
					buildRequest: async fileToUpload => {
						const fd = new FormData();
						fd.append("foundationIssues", fileToUpload.file);
						await client.uploadFile({
							form: fd,
							appealId: details!.appeal_id.toString(),
							onProgress: pe => {
								setFilesToUpload(prev =>
									prev.map(fu => {
										if (fu.file !== fileToUpload.file) return fu;
										return {
											...fu,
											progress: pe,
										};
									})
								);
							},
						});
					},
				});

				send({
					type: "update_context",
					context: {
						files: [
							...propertySurveyContext.files,
							...success.map(fileToUpload => {
								const fileData: FileData = {
									file_name: "foundationIssues-" + fileToUpload.file.name,
									question: "foundationIssues",
									is_ownwell_document: 0,
								};
								return fileData;
							}),
						],
					},
				});

				if (errors.length > 0) {
					setError(true);
					return;
				}
			}

			if (!filesUploaded) {
				setSkipModalOpen(true);
			} else {
				next();
			}
		} catch (e) {
			console.error(e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const btnDisabled = useMemo(() => {
		const dateIsValid =
			foundationIssueDate.length === 0 || foundationIssueDate.length === 10;
		return (
			submitting ||
			!foundationIssueList.length ||
			!foundationIssueDescription ||
			!foundationIssuesCost ||
			!dateIsValid
		);
	}, [
		submitting,
		foundationIssueList,
		foundationIssueDescription,
		foundationIssuesCost,
		foundationIssueDate,
	]);

	const handleTooltipClick = () => {
		setEvidenceModalOpen(true);
		setHasEvidenceModalBeenOpened(true);
	};

	const handleSkip = () => {
		if (!filesUploaded) {
			setSkipModalOpen(true);
		} else {
			next();
		}
	};

	const lastYear = useMemo(
		() => (details?.tax_year ?? new Date().getFullYear()) - 1,
		[details]
	);

	const costUnder5000 = useMemo(() => {
		return (
			foundationIssuesCost !== "" &&
			Number(foundationIssuesCost.replace(/\D/g, "")) < 5000
		);
	}, [foundationIssuesCost]);

	const showDocumentsRecommendedWarning = useMemo(() => {
		if (
			(foundationIssueList.length > 0 ||
				foundationIssueDescription !== "" ||
				foundationIssuesCost !== "" ||
				foundationIssueDate !== "") &&
			!filesUploaded
		) {
			return true;
		}
		return false;
	}, [
		foundationIssueList,
		foundationIssueDescription,
		foundationIssueDate,
		foundationIssuesCost,
		filesUploaded,
	]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			{evidenceModalOpen && <EvidenceModal setOpen={setEvidenceModalOpen} />}
			<QuestionCard
				id="foundation-issues"
				tooltipText="If a property has any foundation or structural issues, we can use evidence and anecdotes surrounding the issues to argue for a larger tax reduction on your behalf. If you're unsure if the problems are significant enough, always tell us about them, and we will do our best to use the information you give us to your benefit."
				onTooltipClick={() => handleTooltipClick()}
				filesLabel="Quotes, Invoices, and/or Photos showing proof of damage"
				withRadio={false}
				alreadyUploaded={questionFiles}
				withFiles
				filesToUpload={filesToUpload}
				setFilesToUpload={setFilesToUpload}
				question={<p className="lg bold">Foundation and structural issues</p>}
				subTitle={
					<p className="sm property-profile-question-card-subtitle denim-medium">
						Quotes, invoices, and insurance estimates are the most effective
						ways to get a property tax reduction for foundation and structural
						issues.
						<br />
						<b>
							The assessor’s office rarely grants a tax reduction without
							documentation showing the dollar value of repairs needed.
						</b>
					</p>
				}>
				<div className="property-survey-question-section-seperator" />
				<div className="property-survey-one-column-checkbox-container">
					{FOUDNATION_ISSUE_LIST_OPTIONS.map(name => (
						<Checkbox
							key={name}
							name={name}
							label={name}
							labelEl="p"
							checked={foundationIssueList.includes(name)}
							onChange={e => {
								setFoundationIssueList(el =>
									el.includes(name)
										? el.filter(e => e !== name)
										: el.concat(name)
								);
							}}
						/>
					))}
				</div>
				<div className="property-survey-question-section-seperator" />
				<p className="bold">Description of issues.*</p>
				<p className="sm denim-medium mb-1">
					Describe how the foundation or structural issues have impacted your
					home including location and any damage.
				</p>
				<TextArea
					className="mb-2 w-100"
					value={foundationIssueDescription}
					onChange={e => setFoundationIssueDescription(e.target.value)}
					rows={4}
					required
					placeholder="Description of issues"
				/>
				<p className="bold">What is the estimated impact to property value?*</p>
				<p className="sm denim-medium mb-1">
					Upload repair estimates or insurance claims as support for this value.
					Values under $5,000 are unlikely to have an impact on the assessment
					value.
				</p>
				<TextField
					className={costUnder5000 ? "input-warning" : ""}
					numeric
					required
					placeholder="$"
					value={foundationIssuesCost}
					onChange={e =>
						setFoundationIssuesCost(formatDollarStr(e.target.value) ?? "")
					}
				/>
				{costUnder5000 && (
					<p className="sm terracotta">
						Values less than {formatDollarStr(5000)}, are rarely helpful in
						getting an assessment reduction.
					</p>
				)}
				<p className="bold mt-2">Date of photos</p>
				<p className="sm denim-medium mb-1">
					{PropertySurveyPhotoUploadPrompt}
				</p>
				<TextField
					maxLength={10}
					minLength={10}
					placeholder="MM/DD/YYYY"
					value={foundationIssueDate || ""}
					onChange={e => {
						const formatted = formatDate(e, foundationIssueDate || "");
						const valid = isValidDate(formatted);

						if (valid) {
							setFoundationIssueDate(formatted);
						}
					}}
				/>
			</QuestionCard>
			{showDocumentsRecommendedWarning && (
				<div className="property-profile-question-note-rust">
					<p className="sm">
						We recommend uploading supporting documentation before continuing.
						Evidence submitted without documentation rarely results in a
						reduction.
					</p>
				</div>
			)}
			<ButtonV2
				size="large"
				disabled={btnDisabled}
				type="submit"
				className="mx-auto">
				Continue
			</ButtonV2>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
			{skipModalOpen && (
				<ExitAreYouSure
					top="40%"
					label="Information Requested"
					body={
						<div style={{ marginTop: "8px" }}>
							<p>
								Evidence submitted without documentation rarely results in a
								reduction.
							</p>

							<br />
							<p>
								Please provide supporting documentation now or at a later time
								via your property portal or email.
							</p>
						</div>
					}
					exitBtnContent={
						<>
							<TimeForward />
							<p>Add Info Later</p>
						</>
					}
					onExit={() => {
						setSkipModalOpen(false);
						next(true);
					}}
					onCancel={() => {
						setSkipModalOpen(false);
					}}
				/>
			)}
		</form>
	);
};

export const EvidenceModal = (props: {
	setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
	const { setOpen } = props;

	return (
		<PropertySurveyEvidenceModal onAttemptExit={() => setOpen(false)}>
			<p>
				<b>Evidence of Foundation Issues</b>
			</p>
			<div className="property-survey-evidence-modal-body-text-container">
				<p className="sm">
					Quotes, invoices, and insurance estimates for foundation issues are
					one of the most effective ways to get a property tax reduction. If
					there are any foundation or structure issues, please provide as much
					evidence and documentation as possible.
					<br />
					<br />
					Photos and written descriptions are helpful. However, the assessor’s
					office rarely grants a tax reduction without documentation showing the
					dollar value of repairs needed.
				</p>
			</div>
			<p>Example Documents & Photos</p>
			<div className="property-survey-evidence-modal-image-container-grid">
				<Image
					// openInNewTab
					placeholder="blur"
					src={FoundationIssuesExample3}
					alt="Foundation issues document example image"
					quality={100}
				/>
				<Image
					// openInNewTab
					placeholder="blur"
					src={FoundationIssuesExample4}
					alt="Foundation issues example image"
					quality={100}
				/>
				<Image
					// openInNewTab
					placeholder="blur"
					src={FoundationIssuesExample2}
					alt="Second foundation issues example image"
					quality={100}
				/>
				<Image
					// openInNewTab
					placeholder="blur"
					src={FoundationIssuesExample1}
					quality={100}
					alt="Third foundation issues example image"
				/>
			</div>
			<ButtonV2
				size="mobile-extra-small"
				onClick={() => setOpen(false)}
				className="mx-auto">
				Back to Question
			</ButtonV2>
		</PropertySurveyEvidenceModal>
	);
};

export default FoundationIssues;
