"use client";
import React from "react";
import {
	setup,
	assign,
	EventFromLogic,
	MachineSnapshot,
	fromPromise,
	fromTransition,
	fromCallback,
	ActorRefFrom,
	sendParent,
	sendTo,
} from "xstate";
import merge from "lodash.merge";
import { useActor, useActorRef, useMachine, useSelector } from "@xstate/react";
import { DeepPartial, FileData, OneZeroOrNull } from "utils/types";
import { useEventTracker } from "utils/useEventTracker";
import { AnimatePresence, motion } from "framer-motion";
import ButtonV2 from "components/elements/ButtonV2";

import CloseCrossSVG from "public/survey-builder/close-cross.svg";
import LogoSVG from "public/survey-builder/logo.svg";
import SpeedometerSVG from "public/survey-builder/speedometer.svg";
import EncryptionSVG from "public/survey-builder/encryption.svg";
import LightBulbSVG from "public/survey-builder/light-bulb.svg";
import HouseWithCoinsSVG from "public/survey-builder/house-with-coins.svg";
import formatDollar, { formatDollarStr } from "utils/formatDollar";
import Link from "next/link";
import FileUpload, {
	FileUploadType,
	splitFileUploads,
} from "components/elements/Input/FileUpload";
import { AxiosProgressEvent } from "axios";
import { TextField } from "./Input";
import formatDate from "utils/formatDate";
import { TextFieldProps } from "./Input/TextField";
import { isValidDate } from "utils/validDate";
import CheckboxInput from "./Input/Checkbox";
import TextAreaInput from "./Input/TextArea";
import QuickLink from "./QuickLink";
import { Expand } from "components";
import { Tooltip3 } from "components/elements/Tooltip";
import SelectInput from "components/elements/Input/Select";
import { nanoid } from "nanoid";
import client from "utils/client";

export namespace SurveyBuilder {
	const SurveySessionContext = React.createContext<{
		survey_session_id: string | null;
	}>({
		survey_session_id: null,
	});

	const useSurveySession = () => {
		const context = React.useContext(SurveySessionContext);
		if (!context) {
			return {
				survey_session_id: null,
			};
		}
		return context;
	};

	export const SurveySessionProvider: React.FC = ({ children }) => {
		const [survey_session_id] = React.useState<string | null>(nanoid());
		return (
			<SurveySessionContext.Provider value={{ survey_session_id }}>
				{children}
			</SurveySessionContext.Provider>
		);
	};

	export namespace Component {
		export const Close: React.FC<
			React.ButtonHTMLAttributes<HTMLButtonElement>
		> = ({ children, onClick }) => {
			return (
				<button className="survey-builder-component-close" onClick={onClick}>
					<CloseCrossSVG />
				</button>
			);
		};

		export const Logo: React.FC<React.HTMLAttributes<HTMLOrSVGElement>> = ({
			className,
		}) => <LogoSVG className={`survey-builder-component-logo ${className}`} />;

		export const Speedometer: React.FC<
			React.HTMLAttributes<HTMLOrSVGElement>
		> = ({ className }) => (
			<SpeedometerSVG
				className={`survey-builder-component-speedometer ${className}`}
			/>
		);

		export const Encryption: React.FC<
			React.HTMLAttributes<HTMLOrSVGElement>
		> = ({ className }) => (
			<EncryptionSVG
				className={`survey-builder-component-encryption ${className}`}
			/>
		);

		export const LightBulb: React.FC<
			React.HTMLAttributes<HTMLOrSVGElement>
		> = ({ className }) => (
			<LightBulbSVG
				className={`survey-builder-component-light-bulb ${className}`}
			/>
		);

		export const HouseWithCoins: React.FC<
			React.HTMLAttributes<HTMLOrSVGElement>
		> = ({ className }) => (
			<HouseWithCoinsSVG
				className={`survey-builder-component-house-with-coins ${className}`}
			/>
		);

		export const ProgressBar: React.FC<{ progress?: number }> = ({
			progress,
		}) => (
			<div className="survey-builder-component-progress-bar">
				<motion.div
					className="survey-builder-component-progress-bar-fill"
					initial={{ width: `${0}%` }}
					animate={{ width: `${progress ?? 0}%` }}
				/>
			</div>
		);

		export const Title: React.FC<
			React.HTMLAttributes<HTMLParagraphElement>
		> = ({ children, className }) => (
			<p className={`survey-builder-component-title ${className}`}>
				{children}
			</p>
		);

		export const Subtitle: React.FC<
			React.HTMLAttributes<HTMLParagraphElement>
		> = ({ children, className }) => (
			<p className={`survey-builder-component-subtitle ${className}`}>
				{children}
			</p>
		);

		export const Body: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = ({
			children,
			className,
		}) => (
			<p className={`survey-builder-component-body ${className}`}>{children}</p>
		);

		export const Savings: React.FC<{
			className?: string;
			amount: number;
			label: string;
		}> = ({ className, amount, label = "Average Savings" }) => {
			return (
				<div className={`survey-builder-component-savings ${className}`}>
					<label>{label}</label>
					<p className="no-translate savings">
						<sup>$</sup>
						{formatDollar(amount).replace("$", "")}
					</p>
				</div>
			);
		};

		export namespace Message {
			export const Azure: React.FC<
				React.HTMLAttributes<HTMLParagraphElement>
			> = ({ children, className }) => (
				<div
					className={`sm survey-builder-component-message bg-azure ${className}`}>
					{children}
				</div>
			);

			export const Rust: React.FC<
				React.HTMLAttributes<HTMLParagraphElement>
			> = ({ children, className }) => (
				<div
					className={`sm survey-builder-component-message bg-rust ${className}`}>
					{children}
				</div>
			);

			export const SunflowerLightest: React.FC<
				React.HTMLAttributes<HTMLParagraphElement>
			> = ({ children, className }) => (
				<div
					className={`sm survey-builder-component-message bg-sunflower-lightest ${className}`}>
					{children}
				</div>
			);
		}

		export const TwoInputRow: React.FC = ({ children }) => {
			return (
				<div className="survey-builder-component-two-input-row">{children}</div>
			);
		};

		export namespace Banners {
			const class_prefix = "survey-builder-component-banner";
			interface BannerProps extends React.HTMLAttributes<HTMLDivElement> {
				Icon?: React.ReactNode;
			}

			export const Blonde: React.FC<BannerProps> = ({
				children,
				className,
				Icon,
			}) => (
				<div className={`${class_prefix} bg-blonde-light ${className}`}>
					{Icon}
					<p className="gold">{children}</p>
				</div>
			);

			export const Sky: React.FC<BannerProps> = ({
				children,
				className,
				Icon,
			}) => (
				<div className={`${class_prefix} bg-sky ${className}`}>
					{Icon}
					<p className="royal">{children}</p>
				</div>
			);

			export const SkyLight: React.FC<BannerProps> = ({
				children,
				className,
				Icon,
			}) => (
				<div className={`${class_prefix} bg-sky-light ${className}`}>
					{Icon}
					<p className="royal">{children}</p>
				</div>
			);

			export const Kelly: React.FC<BannerProps> = ({
				children,
				className,
				Icon,
			}) => (
				<div className={`${class_prefix} bg-kelly-light ${className}`}>
					{Icon}
					<p className="kelly-dark">{children}</p>
				</div>
			);

			export const Slate: React.FC<BannerProps> = ({
				children,
				className,
				Icon,
			}) => (
				<div className={`${class_prefix} bg-slate ${className}`}>
					{Icon}
					<p className="denim">{children}</p>
				</div>
			);

			export namespace Templates {
				export const Tip: React.FC<BannerProps> = ({ children }) => (
					<Blonde Icon={<LightBulb />}>{children}</Blonde>
				);
			}
		}

		export namespace Badges {
			export const Kelly: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
				children,
				className,
			}) => (
				<div
					className={`survey-builder-component-badge bg-kelly-light ${className}`}>
					<p className="semibold kelly-dark">{children}</p>
				</div>
			);
		}

		export namespace Blocks {
			export const Block: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
				children,
				className,
				onClick,
			}) => (
				<div
					className={`survey-builder-component-blocks-block ${className}`}
					onClick={onClick}>
					{children}
				</div>
			);

			export const Faded: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
				children,
				className,
				onClick,
			}) => (
				<div
					className={`survey-builder-component-blocks-faded ${className}`}
					onClick={onClick}>
					{children}
				</div>
			);

			export const Outline: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
				children,
				className,
				onClick,
			}) => (
				<div
					className={`survey-builder-component-blocks-outline ${className}`}
					onClick={onClick}>
					{children}
				</div>
			);

			export namespace Templates {
				export const Info: React.FC<{
					header: React.ReactNode;
				}> = ({ header, children }) => {
					return (
						<Block className="survey-builder-component-blocks-template-info space-y-1">
							<Outline className="survey-builder-component-blocks-template-info-header">
								{header}
							</Outline>
							<div className="survey-builder-component-blocks-template-info-container">
								{children}
							</div>
						</Block>
					);
				};
			}
		}

		export const Tooltip: React.FC<React.ComponentProps<typeof Tooltip3>> = ({
			...props
		}) => {
			return (
				<Tooltip3
					{...props}
					className={["survey-builder-component-tooltip", props.className].join(
						" "
					)}
				/>
			);
		};

		export namespace Fields {
			export namespace Radio {
				const class_prefix = "survey-builder-component-fields-radio";
				type SelectionState = string | boolean | OneZeroOrNull;
				type SelectionValue = Exclude<SelectionState, null | undefined>;

				interface IContext {
					selection_state?: SelectionState;
					set_selection_state?: (value: SelectionValue) => void;
					name?: string;
				}

				const Context = React.createContext<IContext>({});

				export const Group: React.FC<
					Required<Pick<IContext, "name" | "set_selection_state">> &
						Pick<IContext, "selection_state"> & {
							layout: "tiles" | "list";
						}
				> = ({
					children,
					selection_state,
					set_selection_state,
					name,
					layout,
				}) => {
					return (
						<Context.Provider
							value={{
								selection_state,
								set_selection_state,
								name,
							}}>
							<div
								className={`${class_prefix}-group ${class_prefix} layout-${layout}`}>
								{children}
							</div>
						</Context.Provider>
					);
				};

				export const Option: React.FC<{
					selection_value: SelectionValue;
					children?:
						| React.ReactNode
						| (({ selected }: { selected: boolean }) => React.ReactNode);
				}> = ({ selection_value, children }) => {
					const { selection_state, set_selection_state, name } =
						React.useContext(Context);

					const selected =
						selection_state !== undefined &&
						selection_state !== null &&
						selection_state === selection_value;

					return (
						<Blocks.Block
							className={[
								`${class_prefix}-option`,
								selected ? "selected" : "",
							].join(" ")}
							onClick={() => set_selection_state?.(selection_value)}>
							{typeof children === "function"
								? children({ selected })
								: children}
							<input
								hidden
								readOnly
								type="radio"
								name={name}
								checked={selected}
								value={selection_value.toString()}
							/>
						</Blocks.Block>
					);
				};

				export const Bullet: React.FC<{ selected: boolean }> = ({
					selected,
				}) => (
					<div
						className={[
							`${class_prefix}-bullet`,
							selected ? "selected" : "",
						].join(" ")}>
						<div className="inner" />
					</div>
				);

				export const BulletWithLabel: React.FC<{
					selected: boolean;
					label: string;
					Icon?: React.ReactNode;
				}> = ({ label, Icon, selected }) => (
					<div className="flex flex-wrap flex-gap-1 items-center justify-between">
						<div className="flex flex-gap-1 items-center">
							<Bullet selected={selected} />
							<p className="sm mb-0_5 normal-case">{label}</p>
						</div>
						{Icon}
					</div>
				);

				export namespace Templates {
					export const SolidBlocks: React.FC<
						React.ComponentProps<typeof Group> & {
							options: Array<
								Pick<React.ComponentProps<typeof Option>, "selection_value"> &
									Pick<
										React.ComponentProps<typeof BulletWithLabel>,
										"label" | "Icon"
									>
							>;
						}
					> = ({
						layout,
						name,
						selection_state,
						set_selection_state,
						options,
					}) => (
						<Group
							layout={layout}
							name={name}
							selection_state={selection_state}
							set_selection_state={set_selection_state}>
							{options.map(({ selection_value, label, Icon }, idx) => (
								<Option key={idx} selection_value={selection_value}>
									{({ selected }) => (
										<BulletWithLabel
											selected={selected}
											label={label}
											Icon={Icon}
										/>
									)}
								</Option>
							))}
						</Group>
					);
				}
			}

			export namespace Checkbox {
				export type Props = React.ComponentProps<typeof CheckboxInput>;

				export const Default: React.FC<Props> = ({
					containerClassName,
					...rest
				}) => {
					const class_prefix = "survey-builder-component-fields-checkbox";

					return (
						<CheckboxInput
							containerClassName={[class_prefix, containerClassName].join(" ")}
							{...rest}
						/>
					);
				};

				export namespace Templates {
					export const SolidBlock: React.FC<
						{
							checkboxes: Array<Props>;
						} & { className?: string }
					> = ({ checkboxes, className }) => {
						return (
							<Blocks.Block className={["space-y-2", className].join(" ")}>
								{checkboxes.map((checkbox, idx) => (
									<Default key={idx} {...checkbox} />
								))}
							</Blocks.Block>
						);
					};

					export const SolidBlocks: React.FC<
						{
							checkboxes: Array<Props>;
						} & { className?: string }
					> = ({ checkboxes, className }) => {
						return (
							<div className={["space-y-2", className].join(" ")}>
								{checkboxes.map((checkbox, idx) => (
									<Blocks.Block key={idx}>
										<Default {...checkbox} />
									</Blocks.Block>
								))}
							</div>
						);
					};
				}
			}

			export namespace Jumbo {
				const class_prefix = "survey-builder-component-fields-jumbo";
				export const Dollar: React.FC<{
					label: React.ReactNode;
					name: string;
					value?: null | number;
					suffix?: React.ReactNode;
					onChange: (value: number | null) => void;
				}> = ({ children, label, name, value, suffix, onChange }) => {
					const handleChange: React.ChangeEventHandler<
						HTMLInputElement
					> = e => {
						const parsedValue = parseFloat(
							e.target.value.replace(/[^0-9.]/g, "")
						);
						const updateValue = isNaN(parsedValue) ? null : parsedValue;
						onChange(updateValue);
					};

					const formattedValue =
						typeof value === "number"
							? formatDollar(value).replace(/\$/g, "")
							: "";

					return (
						<Blocks.Block className={`${class_prefix}-dollar`}>
							<label htmlFor={name}>{label}</label>
							<div className={`${class_prefix}-dollar-input-section`}>
								<div className="flex">
									<span>$</span>
									<input
										type="text"
										name={name}
										value={formattedValue}
										onChange={handleChange}
									/>
								</div>
								{suffix}
							</div>
						</Blocks.Block>
					);
				};
			}

			export namespace Upload {
				const class_prefix = "survey-builder-component-fields-upload-files";
				export const Files: React.FC<
					React.ComponentProps<typeof FileUpload>
				> = ({
					label,
					alreadyUploaded,
					filesToUpload,
					updateFilesToUpload,
					numOfFilesAccepted,
					accept,
				}) => {
					return (
						<FileUpload
							className={class_prefix}
							label={label}
							alreadyUploaded={alreadyUploaded}
							filesToUpload={filesToUpload}
							updateFilesToUpload={updateFilesToUpload}
							numOfFilesAccepted={numOfFilesAccepted}
							accept={accept}
						/>
					);
				};
			}

			export const Date: React.FC<
				Pick<TextFieldProps, "label" | "containerClassName" | "required"> & {
					value: string;
					setValue: (value: string) => void;
				}
			> = ({ required, label, value, containerClassName, setValue }) => {
				return (
					<TextField
						required={required}
						placeholder="MM/DD/YYYY"
						minLength={10}
						maxLength={10}
						label={label}
						value={value}
						containerClassName={[
							"survey-builder-component-fields-date",
							containerClassName,
						].join(" ")}
						onChange={e => {
							const val = formatDate(e, value ?? "");
							const valid = isValidDate(val);

							if (valid) {
								setValue(val);
							}

							if (val.length === 10) {
								e.target.setCustomValidity("");
							} else {
								e.target.setCustomValidity(
									"Please enter a valid date in the format MM/DD/YYYY."
								);
							}
						}}
						onFocus={e => {
							const val = formatDate(e, value ?? "");
							if (val === "00/00/0000") {
								setValue("");
							}
						}}
					/>
				);
			};

			export const Dollar: React.FC<
				Pick<TextFieldProps, "label" | "containerClassName" | "required"> & {
					value: string;
					setValue: (value: string | null) => void;
				}
			> = ({ required, label, value, containerClassName, setValue }) => {
				return (
					<TextField
						placeholder="$"
						required={required}
						minLength={2}
						label={label}
						containerClassName={[
							"survey-builder-component-fields-dollar",
							containerClassName,
						].join(" ")}
						value={formatDollarStr(value ?? "")}
						onChange={e => {
							const val = formatDollarStr(e.target.value);

							if (val?.replace(/\D/g, "")) {
								setValue(val);
							} else {
								setValue(null);
							}
						}}
					/>
				);
			};

			export const Text: React.FC<
				Pick<
					TextFieldProps,
					| "label"
					| "labelEl"
					| "containerClassName"
					| "required"
					| "placeholder"
					| "onChange"
					| "maxLength"
				> & {
					value: string;
				}
			> = ({
				required,
				placeholder,
				label,
				labelEl,
				value,
				containerClassName,
				onChange,
				maxLength,
			}) => {
				return (
					<TextField
						placeholder={placeholder}
						required={required}
						label={label}
						labelEl={labelEl}
						maxLength={maxLength}
						containerClassName={[
							"survey-builder-component-fields-text",
							containerClassName,
						].join(" ")}
						value={value}
						onChange={onChange}
					/>
				);
			};

			export const TextArea: React.FC<
				React.ComponentProps<typeof TextAreaInput>
			> = ({ containerClassName, className, ...rest }) => {
				return (
					<TextAreaInput
						{...rest}
						containerClassName={[
							"survey-builder-component-fields-textarea",
							containerClassName,
						].join(" ")}
						className={[
							"survey-builder-component-fields-textarea",
							className,
						].join(" ")}
					/>
				);
			};

			export const Select: React.FC<
				React.ComponentProps<typeof SelectInput>
			> = ({
				label,
				className,
				containerClassName,
				value,
				onChange,
				required,
				children,
			}) => {
				return (
					<SelectInput
						label={label}
						className={[
							"survey-builder-component-fields-select-container",
							className,
						].join(" ")}
						containerClassName={[
							"survey-builder-component-fields-select-input",
							containerClassName,
						].join(" ")}
						required={required}
						value={value}
						onChange={onChange}>
						{children}
					</SelectInput>
				);
			};
		}

		export namespace Transition {
			export const DURATION_S = 0.4;
			export const DURATION_MS = DURATION_S * 1000;
			export const useAnimationKey = (state_value?: string) => {
				const [animation_key, set_animation_key] = React.useState<string>();
				React.useEffect(() => {
					if (state_value !== "DONE") set_animation_key(state_value);
				}, [state_value]);

				return animation_key;
			};

			export const Context = React.createContext<{
				direction?: "forward" | "backward";
			}>({});

			export const Swipe: React.FC<{
				activeKey?: string;
				className?: string;
				direction?: "forward" | "backward";
				Step?: React.FC;
			}> = ({ activeKey, className, direction, Step }) => {
				const variants = {
					enter: (direction: string) => {
						return {
							opacity: 0,
							x: direction === "backward" ? "-20%" : "20%",
						};
					},
					center: {
						opacity: 1,
						x: 0,
						transitionEnd: {
							opacity: 1,
							x: 0,
						},
					},
					exit: (direction: string) => {
						return {
							opacity: 0,
							x: direction === "backward" ? "20%" : "-20%",
						};
					},
				};

				const S = React.useMemo(() => {
					const S = () => <>{Step && <Step />}</>;
					return S;
				}, [activeKey]);

				return (
					<motion.div
						className={className}
						key={activeKey}
						custom={direction}
						variants={variants}
						initial={"enter"}
						animate="center"
						exit="exit"
						transition={{
							type: "spring",
							duration: 0.4,
						}}>
						<S />
					</motion.div>
				);
			};
		}

		export const RemoveBodyScroll = () => (
			<style jsx global>{`
				html,
				body {
					overflow: hidden !important;
					position: fixed !important;
					top: 0 !important;
				}
			`}</style>
		);

		export const NavigationRow: React.FC = ({ children }) => (
			<div className="survey-builder-navigation-row">{children}</div>
		);

		export const IntroScreen: React.FC = ({ children }) => {
			return (
				<div className="survey-builder-component-intro-screen">
					<div className="survey-builder-component-intro-screen-header">
						<SurveyBuilder.Component.Logo />
					</div>
					{children}
				</div>
			);
		};

		export const ModalBackdrop: React.FC<
			React.HTMLAttributes<HTMLDivElement>
		> = ({ children, ...props }) => {
			return (
				<div
					{...props}
					className={`survey-builder-component-modal-backdrop ${props.className}`}>
					{children}
				</div>
			);
		};
	}

	export namespace Flow {
		// SurveyValues should include all db fields that can be modified by the survey. (Values used to determine flow logic should also be included here.)
		// Interfaces are optional. (e.g. Sometimes a survey will have optional behaviour based on other account data or where the survey is being used (Public/Private contexts).
		// ... This is where the implementations of these interfaces should be passed in. )
		export interface CompatibleInterface<
			SurveyValues extends {} = {},
			OptionalInterfaces extends {} = {},
			RequiredInterfaces extends {} = {},
			Variant extends string = string,
		> {
			loading: boolean;
			variant?: Variant;
			tracking_values: { [key: string]: any } & {
				flow_version?: string;
				user_id?: number;
				property_id?: number;
			};
			survey_values: SurveyValues;
			optional_interfaces: OptionalInterfaces;
			required_interfaces: null | RequiredInterfaces;
			survey_methods: {
				get: () => Promise<void>;
				update_backend: ({
					survey_values,
					state_value,
				}: {
					survey_values: DeepPartial<SurveyValues>;
					state_value?: string;
				}) => Promise<void>;
			};
		}

		export type CompatibleReactContext<RC> =
			RC extends React.Context<
				CompatibleInterface<
					infer SurveyValues,
					infer OptionalInterfaces,
					infer RequiredInterfaces,
					infer Variant
				>
			>
				? React.Context<
						CompatibleInterface<
							SurveyValues,
							OptionalInterfaces,
							RequiredInterfaces,
							Variant
						>
					>
				: never;

		type InferContextType<RC extends React.Context<any>> =
			RC extends React.Context<infer CT> ? (CT extends {} ? CT : never) : never;

		type InferSurveyValuesFrom<RC extends React.Context<any>> =
			RC extends React.Context<infer CT>
				? CT extends CompatibleInterface<infer SV>
					? SV
					: never
				: never;

		export type MachineContext<RC extends React.Context<any>> =
			InferContextType<CompatibleReactContext<RC>> & {
				loading?: boolean;
				direction?: "forward" | "backward";
				progress?: number;
				is_step_valid?: (
					machine_context: MachineContext<CompatibleReactContext<RC>>
				) => boolean;
				Step?: React.FC;
				NavigationRow?: React.FC<{
					TemplateNavigationRow: React.FC<any>;
				}>;
				error_message?: string;
				staged_file_uploads?: FileUploadType[];
				on_exit?: () => void;
			} & {
				variant?: string;
			};

		type MachineEvents<RC extends React.Context<any>> =
			| {
					type: "update_machine_context";
					machine_context: DeepPartial<
						MachineContext<CompatibleReactContext<RC>>
					>;
			  }
			| {
					type: "put_machine_context";
					machine_context: Partial<MachineContext<CompatibleReactContext<RC>>>;
			  }
			| { type: "init" }
			| { type: "resume" }
			| { type: "restart" }
			| { type: "close" }
			| { type: "next" }
			| { type: "back" }
			| { type: "intro" }
			| { type: "skip_intro" }
			| { type: "skip" }
			| { type: "step_viewed" }
			| {
					type: "update_backend";
			  }
			| {
					type: "set_staged_file_uploads";
					fn: (file_uploads: FileUploadType[]) => FileUploadType[];
			  }
			| {
					type: "set_error_message";
					error_message: string;
			  }
			| {
					type: "callback_success";
			  }
			| {
					type: "callback_error";
			  }
			| {
					type: "set_direction_forward";
			  }
			| {
					type: "set_direction_backward";
			  };

		const createFlowActors = <RC extends React.Context<any>>() => ({
			update_backend: fromCallback<
				MachineEvents<CompatibleReactContext<RC>>,
				MachineContext<CompatibleReactContext<RC>> & { state_value: string }
			>(({ input, sendBack }) => {
				if (!input.survey_methods.update_backend) {
					sendBack({
						type: "set_error_message",
						error_message: "No update_backend method defined.",
					});
					sendBack({ type: "callback_error" });
				} else {
					Promise.all([
						input.survey_methods?.update_backend({
							survey_values: input?.survey_values,
							state_value: input.state_value,
						}),
						new Promise(resolve => setTimeout(resolve, 200)),
					])
						.then(() => {
							sendBack({ type: "callback_success" });
						})
						.catch(e => {
							const error =
								e instanceof Error ? e : new Error("An error occurred.");
							sendBack({
								type: "set_error_message",
								error_message: error.message,
							});
							sendBack({ type: "callback_error" });
						});
				}
			}),
			upload_files: fromCallback<
				MachineEvents<RC>,
				{
					context: MachineContext<RC>;
					handle_file_upload_request: ({
						file_to_upload,
						on_progress,
					}: {
						file_to_upload: FileUploadType;
						on_progress: (pe: AxiosProgressEvent) => void;
					}) => Promise<void>;
					set_file_uploads_results: ({
						context,
					}: { context: MachineContext<RC> } & Awaited<
						ReturnType<typeof splitFileUploads>
					>) => DeepPartial<MachineContext<RC>>;
				}
			>(({ input, sendBack }) => {
				const staged_file_uploads = input.context.staged_file_uploads ?? [];
				splitFileUploads({
					filesToUpload: staged_file_uploads,
					updateFilesToUpload: fn =>
						sendBack({
							type: "set_staged_file_uploads",
							fn,
						}),
					buildRequest: async file_to_upload =>
						await input.handle_file_upload_request({
							file_to_upload,
							on_progress: pe =>
								sendBack({
									type: "set_staged_file_uploads",
									fn: (prev: FileUploadType[]) =>
										prev.map(fu => {
											if (fu.file !== file_to_upload.file) return fu;
											return {
												...fu,
												progress: pe,
											};
										}),
								}),
						}),
				})
					.then(({ success, errors }) => {
						sendBack({
							type: "update_machine_context",
							machine_context: {
								...input.set_file_uploads_results({
									context: input.context,
									success,
									errors,
								}),
							},
						});

						if (errors.length > 0) {
							sendBack({
								type: "set_error_message",
								error_message: errors.map(({ message }) => message).join(", "),
							});
							sendBack({
								type: "callback_error",
							});
						} else {
							sendBack({
								type: "callback_success",
							});
						}
					})
					.catch(e => {
						sendBack({
							type: "set_error_message",
							error_message: "An error occured. While uploading files.",
						});
						sendBack({
							type: "callback_error",
						});
					});
			}),
		});

		export const createFlowMachineFactory = <RC extends React.Context<any>>() =>
			setup({
				types: {
					context: {} as MachineContext<RC>,
					events: {} as MachineEvents<RC>,
				},
				actions: {
					update_machine_context: assign(({ context, event }) => {
						if ("machine_context" in event) {
							return merge(context, event.machine_context);
						}
						return context;
					}),
					put_machine_context: assign(({ context, event }) => {
						if ("machine_context" in event) {
							return {
								...context,
								...event.machine_context,
								tracking_values: merge(
									context.tracking_values,
									event.machine_context.tracking_values
								),
							};
						}
						return context;
					}),
					set_error_message: assign(({ context, event }) => {
						const error_message =
							event.type === "set_error_message"
								? event.error_message
								: "An error occurred. Please try again.";
						return {
							...context,
							error_message,
						};
					}),
					clear_error_message: assign(({ context, event }) => {
						return {
							...context,
							error_message: undefined,
						};
					}),
					set_direction_forward: assign(({ context, event }) => {
						return {
							...context,
							direction: "forward",
						};
					}),
					set_direction_backward: assign(({ context, event }) => {
						return {
							...context,
							direction: "backward",
						};
					}),
					set_staged_file_uploads: assign(({ context, event, self }) => {
						if (event.type !== "set_staged_file_uploads") {
							return context;
						}

						return {
							...context,
							staged_file_uploads: event.fn(context.staged_file_uploads ?? []),
						};
					}),
					track: ({ context, event }) => {},
					exit: ({ context }) => {},
				},
				guards: {},
				delays: {},
				actors: {} as ReturnType<typeof createFlowActors<RC>>,
			});

		type FlowMachineFactory<RC extends React.Context<any>> = ReturnType<
			typeof createFlowMachineFactory<CompatibleReactContext<RC>>
		>;

		export type FlowMachineConfig<RC extends React.Context<any>> = Parameters<
			FlowMachineFactory<CompatibleReactContext<RC>>["createMachine"]
		>[0] & { id: string };

		type FlowMachine<RC extends React.Context<any>> = ReturnType<
			FlowMachineFactory<CompatibleReactContext<RC>>["createMachine"]
		>;
		export interface IContext<RC extends React.Context<any>> {
			machine_context?: MachineContext<CompatibleReactContext<RC>>;
			progress?: number;
			done?: boolean;
			flow_id?: string;
			embedded_flow_id?: string;
			state_value?: string;
			status?: "active" | "done" | "error" | "stopped";
			// derived
			show_back?: boolean;
			show_next?: boolean;
			next_disabled?: boolean;
			// nav
			skip_intro?: () => void;
			skip?: () => void;
			next?: () => void;
			back?: () => void;
			close?: () => void;
			restart?: () => void;
			step_viewed?: () => void;
			update_machine_context?: (mc: DeepPartial<MachineContext<RC>>) => void;
			update_backend?: () => void;
			set_staged_file_uploads?: (
				fn: (file_uploads: FileUploadType[]) => FileUploadType[]
			) => void;
			set_on_exit?: (exit?: () => void) => void;
			RestartButton: React.FC<React.ComponentProps<typeof ButtonV2>>;
			NextButton: React.FC<React.ComponentProps<typeof ButtonV2>>;
			BackButton: React.FC<React.ComponentProps<typeof ButtonV2>>;
			CloseButton: React.FC<React.ComponentProps<typeof ButtonV2>>;
			SkipButton: React.FC<React.ComponentProps<typeof QuickLink>>;
		}

		const xstate_value_to_state_value = (xstate_value: any) => {
			if (typeof xstate_value === "string") return xstate_value;
			return Object.keys(xstate_value)[0];
		};

		export const build = <
			RC extends React.Context<any>,
			FM extends FlowMachine<RC>,
			MC extends MachineContext<RC>,
		>({
			reactContext,
			flowMachine,
		}: {
			reactContext: RC;
			flowMachine: FM;
		}) => {
			const useFlowMachine = ({
				initial_event,
				implementations = {},
			}: {
				initial_event?: MachineEvents<RC>;
				implementations: Parameters<typeof flowMachine.provide>[0];
			}) => {
				const initial_event_ref = React.useRef<MachineEvents<RC>>(
					initial_event ?? { type: "resume" }
				);
				const [state, send] = useMachine(flowMachine.provide(implementations));

				const {
					loading,
					variant,
					survey_values,
					optional_interfaces,
					required_interfaces,
					survey_methods,
					tracking_values,
				} = React.useContext(reactContext);

				const state_value = React.useMemo(
					() => xstate_value_to_state_value(state.value),
					[state.value]
				);

				React.useEffect(() => {
					if (state.status === "done") return;
					send({
						type: "put_machine_context",
						machine_context: {
							loading,
							variant,
							optional_interfaces,
							required_interfaces,
							survey_methods,
							tracking_values,
							survey_values,
						} as Partial<MC>,
					});
					if (loading) {
						send({ type: "init" });
					} else {
						send(initial_event_ref.current);
						initial_event_ref.current = { type: "resume" };
					}
				}, [
					variant,
					state.status,
					optional_interfaces,
					required_interfaces,
					survey_methods,
					tracking_values,
					survey_values,
					survey_values,
					loading,
				]);

				const skip_intro = () => {
					send({ type: "skip_intro" });
				};

				const back = () => {
					send({ type: "back" });
				};

				const next = () => {
					send({ type: "next" });
				};

				const close = () => {
					send({ type: "close" });
				};

				const skip = () => {
					send({ type: "skip" });
				};

				const restart = () => {
					send({ type: "restart" });
				};

				const step_viewed = () => {
					send({ type: "step_viewed" });
				};

				const update_machine_context = (mc: DeepPartial<MC>) => {
					send({
						type: "update_machine_context",
						machine_context: {
							...mc,
						},
					});
				};

				const update_backend = () => {
					send({
						type: "update_backend",
					});
				};

				const set_staged_file_uploads = (
					fn: (file_uploads: FileUploadType[]) => FileUploadType[]
				) => {
					send({
						type: "set_staged_file_uploads",
						fn,
					});
				};

				const set_on_exit = (on_exit?: () => void) => {
					update_machine_context({
						on_exit,
					} as DeepPartial<MC>);
				};

				const show_back = React.useMemo(
					() => state.can({ type: "back" }),
					[state]
				);

				const show_next = React.useMemo(
					() => state.can({ type: "next" }),
					[state]
				);

				const show_restart = React.useMemo(
					() => state.can({ type: "restart" }),
					[state]
				);

				const show_close = React.useMemo(
					() => state.can({ type: "close" }),
					[state]
				);

				const show_skip = React.useMemo(
					() => state.can({ type: "skip" }),
					[state]
				);

				const next_disabled = React.useMemo(
					() => !state.context?.is_step_valid?.(state.context),
					[state]
				);

				return {
					close,
					skip_intro,
					restart,
					next,
					back,
					skip,
					step_viewed,
					update_machine_context,
					update_backend,
					set_staged_file_uploads,
					set_on_exit,
					show_back,
					show_next,
					show_restart,
					show_close,
					show_skip,
					next_disabled,
					status: state.status,
					machine_context: state.context,
					progress: state.context?.progress,
					done: state.status === "done",
					state_value,
				};
			};

			const FlowContext = React.createContext<IContext<RC>>({
				RestartButton: () => null,
				NextButton: () => null,
				BackButton: () => null,
				CloseButton: () => null,
				SkipButton: () => null,
			});

			const useFlow = () => {
				const context = React.useContext(FlowContext);
				if (!context) {
					throw new Error("useFlow must be used within a FlowProvider");
				}
				return context;
			};

			const FlowProvider: React.FC<{
				id_prefix?: string;
				initial_event?: MachineEvents<RC>;
			}> = ({ children, id_prefix, initial_event }) => {
				const trackEvent = useEventTracker();
				const { variant } = React.useContext(reactContext);
				const { survey_session_id } = useSurveySession();
				const flow_id = `${flowMachine.id}${variant ? `_${variant}` : ""}`;
				const embedded_flow_id = `${id_prefix ? `${id_prefix}_` : ""}${flow_id}`;
				const {
					close,
					skip_intro,
					restart,
					next,
					back,
					skip,
					step_viewed,
					update_machine_context,
					update_backend,
					set_staged_file_uploads,
					set_on_exit,
					show_back,
					show_next,
					show_restart,
					show_close,
					show_skip,
					next_disabled,
					status,
					machine_context,
					progress,
					done,
					state_value,
				} = useFlowMachine({
					initial_event,
					implementations: {
						actions: {
							exit: ({ context }) => context?.on_exit?.(),
							track: ({ context, event, self }) => {
								if (event.type === "update_machine_context") return;
								const {
									property_id,
									user_id,
									flow_version,
									...flow_event_data
								} = context.tracking_values;
								const flow_name = `${flow_id.toUpperCase()}_FLOW`;
								const flow_step = xstate_value_to_state_value(
									self.getSnapshot().value
								);
								const flow_event = event.type.toUpperCase();
								const flow_session_id = survey_session_id;
								// duplicate mixpanel events for dashboard configurability
								trackEvent({
									eventName: `${id_prefix ? `${id_prefix}_` : ""}${flow_name}_${flow_step}_${flow_event}`,
									data: {
										...flow_event_data,
										flow_session_id,
									},
								});
								trackEvent({
									eventName: `${id_prefix ? `${id_prefix}_` : ""}${flow_name}_${flow_step}_EVENT`,
									data: {
										...flow_event_data,
										flow_event,
										flow_session_id,
									},
								});
								trackEvent({
									eventName: `${id_prefix ? id_prefix : flow_name}`,
									data: {
										...flow_event_data,
										flow_step: `${id_prefix ? `${flow_name}_` : ""}${flow_step}`,
										flow_event,
										flow_session_id,
									},
								});
								if (flow_session_id && !!flow_version) {
									client
										.flowEvent({
											property_id: property_id ?? null,
											flow_session_id,
											flow_name: id_prefix ? id_prefix : flow_name,
											flow_version,
											flow_event,
											flow_step: `${id_prefix ? `${flow_name}_` : ""}${flow_step}`,
											flow_event_data,
										})
										.catch(e => {});
								}
							},
						},
						actors: {
							...createFlowActors<RC>(),
						},
					},
				});

				const BackButton: React.FC<React.ComponentProps<typeof ButtonV2>> = ({
					children,
					className,
					variant = "primary-outline",
					size,
					onClick,
				}) =>
					show_back ? (
						<ButtonV2
							onClick={onClick ?? back}
							className={className}
							variant={variant}
							size={size}>
							{children}
						</ButtonV2>
					) : null;

				const RestartButton: React.FC<
					React.ComponentProps<typeof ButtonV2>
				> = ({
					children,
					className,
					variant = "primary-outline",
					size,
					onClick,
				}) =>
					show_restart ? (
						<ButtonV2
							onClick={onClick ?? restart}
							className={className}
							variant={variant}
							size={size}>
							{children}
						</ButtonV2>
					) : null;

				const NextButton: React.FC<React.ComponentProps<typeof ButtonV2>> = ({
					children,
					className,
					variant,
					size,
					onClick,
				}) =>
					show_next ? (
						<ButtonV2
							onClick={onClick ?? next}
							disabled={next_disabled}
							className={className}
							variant={variant}
							size={size}>
							{children}
						</ButtonV2>
					) : null;

				const CloseButton: React.FC<React.ComponentProps<typeof ButtonV2>> = ({
					children,
					className,
					variant,
					size,
					onClick,
				}) =>
					show_close ? (
						<ButtonV2
							onClick={onClick ?? close}
							className={className}
							variant={variant}
							size={size}>
							{children}
						</ButtonV2>
					) : null;

				const SkipButton: React.FC<React.ComponentProps<typeof QuickLink>> = ({
					children,
					className,
					onClick,
					size = "small",
				}) =>
					show_skip ? (
						<QuickLink
							asButton
							onClick={onClick ?? skip}
							className={className}
							size={size}>
							{children}
						</QuickLink>
					) : null;

				return (
					<FlowContext.Provider
						value={{
							flow_id,
							embedded_flow_id,
							status,
							machine_context,
							show_back,
							show_next,
							next_disabled,
							close,
							restart,
							next,
							back,
							skip,
							skip_intro,
							step_viewed,
							update_machine_context,
							update_backend,
							set_staged_file_uploads,
							set_on_exit,
							RestartButton,
							NextButton,
							BackButton,
							CloseButton,
							SkipButton,
							progress,
							done,
							state_value,
						}}>
						{children}
					</FlowContext.Provider>
				);
			};

			const useFlowExit = (exit?: () => void) => {
				const { set_on_exit } = useFlow();
				React.useEffect(() => {
					set_on_exit?.(exit);
				}, []);
			};

			const FlowExit = ({ exit }: { exit?: () => void }) => {
				useFlowExit(exit);
				return null;
			};

			return {
				FlowContext,
				FlowProvider,
				useFlow,
				useFlowExit,
				FlowExit,
			};
		};

		export const useStepViewed = <RC extends React.Context<any>>(
			FlowContext: React.Context<Flow.IContext<RC>>
		) => {
			const { state_value, step_viewed } = React.useContext(FlowContext);

			React.useEffect(() => {
				if (!!state_value && !["IDLE", "DONE"].includes(state_value))
					step_viewed?.();
			}, [state_value]);
		};
	}
	export namespace Templates {
		export namespace SingleFlow {
			export const FullScreen = <RC extends React.Context<any>>({
				FlowContext,
			}: {
				FlowContext: React.Context<Flow.IContext<RC>>;
			}) => {
				const {
					state_value,
					flow_id,
					close,
					machine_context,
					NextButton,
					BackButton,
				} = React.useContext(FlowContext);
				Flow.useStepViewed(FlowContext);

				const { direction, progress, Step, NavigationRow, error_message } =
					machine_context ?? {};

				const TemplateNavigationRow = () => {
					const { BackButton, NextButton } = React.useContext(FlowContext);
					return (
						<Component.NavigationRow>
							<BackButton className="mr-auto" size="small">
								Back
							</BackButton>
							<NextButton className="ml-auto" size="small">
								Next
							</NextButton>
						</Component.NavigationRow>
					);
				};

				const animation_key = Component.Transition.useAnimationKey(state_value);
				const reset_scroll_ref = React.useRef<HTMLDivElement>(null);
				React.useEffect(() => {
					if (animation_key) {
						setTimeout(() => {
							reset_scroll_ref.current?.scrollTo({
								top: 0,
								left: 0,
								behavior: "instant",
							});
						}, Component.Transition.DURATION_MS + 100);
					}
				}, [animation_key]);

				return (
					<div
						id={`${flow_id?.toString().toLowerCase().replace(/\_/g, "-")}-survey`}>
						<Component.RemoveBodyScroll />
						<div
							className="survey-builder-template-single-flow-fullscreen"
							ref={reset_scroll_ref}>
							<div className="survey-builder-template-single-flow-fullscreen-header">
								<div className="survey-builder-template-single-flow-fullscreen-header-content">
									<div className="spacer" />
									<Component.Logo />
									<Component.Close onClick={close} />
								</div>
								<Component.ProgressBar progress={progress} />
							</div>
							<div className="survey-builder-template-single-flow-fullscreen-body">
								<AnimatePresence exitBeforeEnter custom={direction}>
									<Component.Transition.Swipe
										key={animation_key}
										direction={direction}
										activeKey={animation_key}
										Step={Step}
									/>
								</AnimatePresence>
							</div>
							<div className="survey-builder-template-single-flow-fullscreen-sticky-bottom">
								{error_message && (
									<p className="mx-auto rust text-center py-2 bg-white">
										{error_message}
									</p>
								)}
								{NavigationRow && (
									<NavigationRow
										TemplateNavigationRow={TemplateNavigationRow}
									/>
								)}
								<div className="survey-builder-template-single-flow-fullscreen-footer">
									<div className="flex justify-center flex-gap-2 flex-wrap">
										<div className="flex flex-gap-1 items-center">
											<Component.Speedometer />
											<p className="extra-small denim_5 nowrap">
												No impact on credit score
											</p>
										</div>
										<div className="flex flex-gap-1 items-center">
											<Component.Encryption />
											<p className="extra-small denim_5 nowrap">
												Your data security is important to us
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			};

			export const Embedded = <RC extends React.Context<any>>({
				FlowContext,
			}: {
				FlowContext: React.Context<Flow.IContext<RC>>;
			}) => {
				const { embedded_flow_id, state_value, close, machine_context } =
					React.useContext(FlowContext);
				Flow.useStepViewed(FlowContext);

				const { direction, progress, Step, NavigationRow, error_message } =
					machine_context ?? {};

				const TemplateNavigationRow = () => {
					const { BackButton, NextButton } = React.useContext(FlowContext);
					return (
						<Component.NavigationRow>
							<BackButton className="mr-auto" size="small">
								Back
							</BackButton>
							<NextButton className="ml-auto" size="small">
								Next
							</NextButton>
						</Component.NavigationRow>
					);
				};

				const animation_key = Component.Transition.useAnimationKey(state_value);
				const reset_scroll_ref = React.useRef<HTMLDivElement>(null);
				React.useEffect(() => {
					if (animation_key) {
						reset_scroll_ref.current?.parentElement
							?.closest(
								".survey-builder-template-multiple-flows-fullscreen-flow-body"
							)
							?.scrollTo({
								top: 0,
								left: 0,
								behavior: "instant",
							});
					}
				}, [animation_key]);

				return (
					<div
						ref={reset_scroll_ref}
						id={`${embedded_flow_id?.toString().toLowerCase().replace(/\_/g, "-")}-survey-builder`}
						className="survey-builder-template-single-flow-embedded">
						<Component.Transition.Context.Consumer>
							{({ direction: parent_direction }) => (
								<AnimatePresence
									exitBeforeEnter
									custom={direction ?? parent_direction}>
									<Component.Transition.Swipe
										className="survey-builder-template-single-flow-embedded-body"
										key={state_value}
										direction={direction ?? parent_direction}
										activeKey={state_value}
										Step={() => (
											<>
												{Step && <Step />}
												{NavigationRow && (
													<NavigationRow
														TemplateNavigationRow={TemplateNavigationRow}
													/>
												)}
											</>
										)}
									/>
								</AnimatePresence>
							)}
						</Component.Transition.Context.Consumer>
						{error_message && (
							<p className="mx-auto rust text-center py-2 bg-white">
								{error_message}
							</p>
						)}
					</div>
				);
			};
		}

		export namespace MultipleFlows {
			export const StepHeader = <RC extends React.Context<any>>({
				center_text,
				right_text,
				show_back,
				back,
				className,
			}: {
				center_text: string;
				right_text?: string;
				className?: string;
			} & Pick<Flow.IContext<RC>, "show_back" | "back">) => {
				const class_prefix =
					"survey-builder-template-multiple-flows-step-header";
				return (
					<div className={`${class_prefix} ${className}`}>
						{show_back ? (
							<QuickLink
								semibold
								asButton
								reverse
								iconPosition="left"
								onClick={back}>
								Back
							</QuickLink>
						) : (
							<div />
						)}
						<p className={`${class_prefix}-center-text`}>{center_text}</p>
						{right_text ? (
							<p className="azure semibold text-right">{right_text}</p>
						) : (
							<div />
						)}
					</div>
				);
			};

			export const FullScreen = <RC extends React.Context<any>>({
				nav,
				header,
				FlowContext,
			}: {
				nav: React.ReactNode;
				header: React.ReactNode;
				FlowContext: React.Context<Flow.IContext<RC>>;
			}) => {
				const {
					flow_id,
					state_value,
					close,
					machine_context,
					NextButton,
					BackButton,
					show_back,
					back,
					step_viewed,
				} = React.useContext(FlowContext);
				Flow.useStepViewed(FlowContext);

				const { direction, progress, Step, NavigationRow, error_message } =
					machine_context ?? {};

				const TemplateNavigationRow = ({
					center_text,
				}: {
					center_text: string;
				}) => {
					return (
						<SurveyBuilder.Templates.MultipleFlows.StepHeader
							show_back={show_back}
							back={back}
							center_text={center_text}
						/>
					);
				};

				const animation_key = Component.Transition.useAnimationKey(state_value);
				const reset_scroll_ref = React.useRef<HTMLDivElement>(null);
				React.useEffect(() => {
					if (animation_key) {
						reset_scroll_ref.current?.scrollTo({
							top: 0,
							left: 0,
							behavior: "instant",
						});
					}
				}, [animation_key]);

				return (
					<div
						id={`${flow_id?.toString().toLowerCase().replace(/\_/g, "-")}-survey-builder`}>
						<Component.RemoveBodyScroll />
						<div className="survey-builder-template-multiple-flows-fullscreen">
							<div className="survey-builder-template-multiple-flows-fullscreen-header">
								<div className="survey-builder-template-multiple-flows-fullscreen-header-content">
									<Component.Logo />
									{/* <Component.Close
										onClick={() => {
											close?.();
										}}
									/> */}
									{header}
								</div>
							</div>
							<div className="survey-builder-template-multiple-flows-fullscreen-body">
								<div className="survey-builder-template-multiple-flows-fullscreen-nav">
									{nav}
								</div>
								<div
									className="survey-builder-template-multiple-flows-fullscreen-flow-body"
									ref={reset_scroll_ref}>
									{NavigationRow && (
										<NavigationRow
											TemplateNavigationRow={TemplateNavigationRow}
										/>
									)}
									<Component.Transition.Context.Provider value={{ direction }}>
										<AnimatePresence exitBeforeEnter custom={direction}>
											<Component.Transition.Swipe
												className="survey-builder-template-multiple-flows-fullscreen-flow-body-step"
												key={animation_key}
												activeKey={animation_key}
												direction={direction}
												Step={Step}
											/>
										</AnimatePresence>
									</Component.Transition.Context.Provider>
								</div>
							</div>
						</div>
					</div>
				);
			};
		}
	}
}
