import { useEffect, useState, useMemo, useCallback } from "react";
import { usePropertyContext } from "components/pages/Account/Property/context";
import QuestionCard from "elements/QuestionCard";
import RightArrow from "assets/imgs/right-arrow-full.svg";
import { FileData, OneZeroOrNull } from "utils/types";
import Select from "elements/Input/Select";

import { INTERNET_OPTIONS } from "../../BillReduction/BillReductionModal";
import { TextField } from "elements/Input";
import formatDate from "utils/formatDate";
import { isValidDate } from "utils/validDate";
import FileUpload, { FileUploadType } from "elements/Input/FileUpload";
import ButtonV2 from "elements/ButtonV2";
import moment from "moment";
import client from "utils/client";

import { INSURANCE_MARITAL_STATUS_OPTIONS } from "../../InsuranceSurvey/questions/MaritalStatus";
import { INSURANCE_CARRIER_OPTIONS } from "../../InsuranceSurvey/utils";
import { formatDollarStr } from "utils/formatDollar";
import { useAuthContext } from "context/AuthContext";
import { sendWebhookToInsurance } from "utils/sendWebhook";

const OTHER_OPTION_VALUE = "__OTHER__";

export const InsuranceMonitoring = () => {
	const { userData } = useAuthContext();
	const { details, next } = usePropertyContext();

	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(false);

	const [maritalStatus, setMaritalStatus] = useState("");
	const [dobValue, setDobValue] = useState("");
	const [insuranceProvider, setInsuranceProvider] = useState("");

	const [showOtherInsuranceProviderField, setShowOtherInsuranceProviderField] =
		useState(false);
	const [otherInsuranceProvider, setOtherInsuranceProvider] = useState("");

	const [annualPremium, setAnnualPremium] = useState("");

	const [deductible, setDeductible] = useState("");

	const handleSubmit = async () => {
		if (!details || !userData) return;
		try {
			setSubmitting(true);
			setError(false);

			await client.submitInsuranceSurvey({
				meta: { customer_id: userData.id, property_id: details.id },
				answers: {
					marital_status: maritalStatus,
					birthday: dobValue,
					current_provider: showOtherInsuranceProviderField
						? otherInsuranceProvider
						: insuranceProvider,
					current_premium: annualPremium,
					current_deductible: deductible,
				},
			});
			sendWebhookToInsurance(
				"Insurance Survey Complete. User ID: " +
					userData!.id +
					" Address: " +
					details?.address +
					(details ? " " + details?.address2 : "") +
					" " +
					details?.city +
					" " +
					details?.state +
					" " +
					details?.zip_code +
					"/n https://www.realappeal.tax/customer/" +
					userData.id
			);
			next();
		} catch (e) {
			console.error("ERROR", e);
			setError(true);
		} finally {
			setSubmitting(false);
		}
	};

	const handleSkip = () => {
		next(true);
	};

	const commonInsuranceProviderFieldsProps = {
		required: true,
		onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
			const value = e.target.value;

			if (value === OTHER_OPTION_VALUE) {
				setShowOtherInsuranceProviderField(true);
				setInsuranceProvider(value);
				setOtherInsuranceProvider(value);
				return;
			}

			if (e.target.nodeName === "SELECT") {
				setInsuranceProvider(value);
			} else {
				setOtherInsuranceProvider(value);
			}
		},
	};

	const buttonDisabled = useMemo(() => {
		return (
			!maritalStatus ||
			dobValue?.length !== 10 ||
			!insuranceProvider ||
			(showOtherInsuranceProviderField && !otherInsuranceProvider) ||
			!annualPremium ||
			!deductible
		);
	}, [
		maritalStatus,
		dobValue,
		insuranceProvider,
		showOtherInsuranceProviderField,
		otherInsuranceProvider,
		annualPremium,
		deductible,
	]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
			<QuestionCard
				id="insurance-monitoring"
				tooltipText=""
				question={
					<p className="bold lg">
						Monitor the cost of your home insurance to find opportunities to
						save.
					</p>
				}
				subTitle={
					"We'll work with our partners to regularly check for more affordable insurance options so you don't have to. When it looks like you can save some money, we'll let you know."
				}>
				<div className="space-y-2">
					<Select
						value={insuranceProvider}
						label="Current Home Insurance Provier"
						{...commonInsuranceProviderFieldsProps}>
						<option hidden disabled value="">
							Select a provider
						</option>
						{INSURANCE_CARRIER_OPTIONS.sort().map(carrier => (
							<option key={carrier} value={carrier}>
								{carrier}
							</option>
						))}
						<option value={OTHER_OPTION_VALUE}>It's not on the list</option>
					</Select>
					{!!insuranceProvider && (
						<>
							<Select
								required
								label="Marital Status"
								value={maritalStatus}
								onChange={e => setMaritalStatus(e.target.value)}>
								<option value="" hidden disabled>
									Select a status
								</option>
								{INSURANCE_MARITAL_STATUS_OPTIONS.map(({ label, value }) => (
									<option key={label} value={value}>
										{label}
									</option>
								))}
							</Select>
							<TextField
								required
								label="Date of Birth"
								placeholder="MM/DD/YYYY"
								containerClassName="exemptions-card-input"
								maxLength={10}
								minLength={10}
								value={dobValue ?? ""}
								onChange={e => {
									const formatted = formatDate(e, dobValue ?? "");
									const valid = isValidDate(formatted);

									if (valid) {
										setDobValue(formatted);
									}
								}}
							/>
							{showOtherInsuranceProviderField && (
								<TextField
									{...commonInsuranceProviderFieldsProps}
									containerClassName="insurance-current-provider-textfield"
									value={otherInsuranceProvider}
									placeholder="Current provider name"
								/>
							)}
							<TextField
								required
								label="Current Annual Premium"
								placeholder="$"
								value={annualPremium}
								onChange={e =>
									setAnnualPremium(formatDollarStr(e.target.value) || "")
								}
							/>
							<TextField
								required
								label="Current Deductible"
								placeholder="$"
								value={deductible}
								onChange={e =>
									setDeductible(formatDollarStr(e.target.value) || "")
								}
							/>
						</>
					)}
				</div>
			</QuestionCard>
			<div>
				<p className="body-tiny denim_5 text-center pb-4">
					By clicking "Submit," you agree that your information from your
					Ownwell account will be shared with third party partners to assist in
					obtaining insurance quotes. You also acknowledge that partners may
					gather data from reputable third parties, including your driving
					records, credit history, and insurance claims history, to provide
					accurate rates.
				</p>
				<ButtonV2 size="large" className="mx-auto" disabled={buttonDisabled}>
					{submitting ? "Submitting..." : "Submit"}
				</ButtonV2>
			</div>
			{error && (
				<p className="sm text-center terracotta">
					Whoops, something went wrong. Try again.
				</p>
			)}
			<p className="profile-question-skip" onClick={() => handleSkip()}>
				Skip <RightArrow />
			</p>
		</form>
	);
};
