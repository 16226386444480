import ButtonV2 from "elements/ButtonV2";
import { Tooltip3 } from "elements/Tooltip";
import CircleCheck from "public/account/survey/concierge/circle-check.svg";
import ShieldCheck from "public/account/survey/concierge/shield-check.svg";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useEffect, useState } from "react";
import formatDollar from "utils/formatDollar";
import { useConciergeContext } from "./ConciergePage";
import { useResponsiveContext } from "context/ResponsiveContext";
import InfoCircle from "public/info-circle.svg";
import HelpTextModal from "./HelpTextModal";
import { usePropertyContext } from "../context";

export interface PlanFeature {
	active: boolean;
	label: string;
	subtext?: string;
	helpText?: string;
	value?: string;
}

const getAllFeatures = (propertyInServicingAppeal: boolean): PlanFeature[] => {
	const baseFeatures: PlanFeature[] = [
		{
			active: true,
			label: "1 on 1 Consultation Onboarding ",
			helpText:
				"Get a thorough, 1 on 1 meeting with your assigned Concierge expert to review every aspect of your property for maximum savings. After subscribing and completing your portal onboarding, you'll be notified to either schedule a call or fill out the digital Concierge questionnaire at your convenience.",
		},
	];

	if (propertyInServicingAppeal) {
		baseFeatures.push({
			active: true,
			label: "Annual Property Tax Appeals",
			helpText:
				"Never overpay on your property taxes again with a high quality property tax appeal each and every year. You only pay if you save!",
		});
	}

	return [
		...baseFeatures,
		{
			active: true,
			label: "Exemption Filing",
			helpText:
				"Primary residence exemptions can further lower your property tax bill. We'll handle every step—from preparing all necessary documents to filing and activating your exemption—while also pursuing any eligible refunds.",
		},
		{
			active: true,
			label: "Exemption Fall Off Monitoring",
			helpText:
				"If we detect that a Concierge property's primary residence exemption has fallen off, which can lead to a severe loss of savings, we'll notify you and help to get it reinstated.",
		},
		{
			active: true,
			label: "Mortgage Reduction",
			helpText:
				"Monitor your mortgage and equity like a pro! Our Concierge team can help qualified subscribers unlock additional savings through potential refinancing, PMI removal, and more.",
		},
		{
			active: true,
			label: "Insurance Reduction",
			value: "$439 avg savings",
			helpText:
				"We'll periodically review your home insurance to uncover potential savings. When an opportunity arises, we'll notify you and collaborate with our partners to secure those savings. Concierge subscriber's policy documents are vetted thoroughly to ensure all opportunities to save are uncovered.",
		},
		{
			active: true,
			label: "Utility Reduction",
			value: "$498 avg savings",
			helpText:
				"In qualifying regions, we can help determine if you're overpaying on your current energy bill. Concierge subscriber's energy bills are reviewed regularly and you will be notified when the time is right to make a switch.",
		},
		{
			active: true,
			label: "Internet Bill Reduction",
			value: "$233 avg savings",
			helpText:
				"Our Bill Reduction Experts can negotiate and work to lower your internet bill directly with your provider so you don't have to! Concierge customer's current bill is vetted to ensure all possible savings are being fully utilized.",
		},
		{
			active: true,
			label: "Unclaimed Property",
			value: "$532 avg savings",
			helpText:
				"We'll search across Concierge subscriber's previous addresses to help to uncover and retrieve any money that might be due to you. This happens sometimes when an entity, organization or business isn't able to get money back to you, in which case it ends up as unclaimed property.",
		},
		{
			active: true,
			label: "Trust Creation ",
			subtext: "(optional)",
			value: "$500+ value",
			helpText:
				"We help our Concierge subscribers determine if creating a trust makes sense for their property and goals. Trusts help to avoid costly probate in the future, create anonymity and can have multiple trustees as needed.",
		},
		{
			active: true,
			label: "Local reports ",
			subtext: "(home values, crime, etc.)",
			helpText:
				"Access neighborhood crime reports and local construction permit details to stay on top of what's happening around your property.",
		},
	];
};

export const ConciergeOptionSlider = () => {
	const { billingCycle, planPrice, setPlanPrice, sliderMin, sliderMax } =
		useConciergeContext();

	const handleSliderChange = (value: number | number[]) => {
		if (typeof value === "number") {
			setPlanPrice(value);
		}
	};

	return (
		<div className="pricing-box">
			<p className="small semibold">Pay What You Think Is Fair</p>
			<p className="xs">
				We believe that if you find value from Concierge, you will support us
				fairly.
			</p>
			<div className="pricing-slider">
				<p className="slider-value h4 w-100 text-center azure mb-2 mt-1">
					{formatDollar(planPrice)}/
					{billingCycle == "annually" ? "year" : "month"}
				</p>
				<div className="slider-container">
					<Slider
						step={1}
						min={sliderMin}
						max={sliderMax}
						value={planPrice}
						onChange={handleSliderChange}
						trackStyle={{ backgroundColor: "#1C7CE5", height: 4 }}
						handleStyle={{
							borderColor: "white",
							border: "1px solid #1C7CE5",
							height: 25,
							width: 25,
							marginLeft: 0,
							marginRight: 0,
							boxSizing: "border-box",
						}}
						railStyle={{ backgroundColor: "#D4D4D4", height: 4 }}
					/>
				</div>
				<p className="w-100 flex justify-between mt-1">
					<span className="semibold denim_5">${sliderMin}</span>
					<span className="semibold denim_5">${sliderMax}</span>
				</p>
			</div>
		</div>
	);
};

export const ConciergeOptionCard = ({
	contingencyPercentage,
	onOptionSelect,
	hideCTA = false,
	isExpandedByDefault = true,
	children,
}: {
	contingencyPercentage: number;
	onOptionSelect: () => void;
	hideCTA?: boolean;
	isExpandedByDefault?: boolean;
	children?: React.ReactNode;
}) => {
	const [isExpanded, setIsExpanded] = useState(true);
	const [showContingencyModal, setShowContingencyModal] = useState(false);
	const { isMobile } = useResponsiveContext();
	const { details } = usePropertyContext();

	useEffect(() => {
		setIsExpanded(isExpandedByDefault);
	}, [isExpandedByDefault]);

	const featuresList = getAllFeatures(
		details?.accepting_appeals == 1 ? true : false
	);

	return (
		<div className="concierge-options-card concierge-option active-border">
			<div className="card-header">
				<p className="card-heading">Concierge</p>
				{hideCTA == false ? (
					<p className="most-popular xs semibold">Most Savings ✨</p>
				) : (
					isMobile && (
						<p
							className="show-hide-toggle-btn xs"
							onClick={() => setIsExpanded((prev: boolean) => !prev)}>
							{isExpanded ? "Show me less" : "What's included?"}
						</p>
					)
				)}
			</div>
			<div className="pricing">
				{children}
				<div className="contingency-box">
					<p
						className="denim-medium flex align-center"
						onClick={() => setShowContingencyModal(true)}>
						{contingencyPercentage ? contingencyPercentage + "% " : "25% "}
						contingency fee
						<InfoCircle className="info-icon fill-denim-5 ml-1" />
					</p>
				</div>
			</div>
			{hideCTA == false && (
				<div className="cta">
					<ButtonV2
						variant="primary"
						size="extra-small"
						className="w-100 no-box-shadow"
						onClick={onOptionSelect}>
						Upgrade To Concierge
					</ButtonV2>
				</div>
			)}
			{isExpanded && <ConciergeFeatures featuresList={featuresList} />}
			{showContingencyModal && (
				<HelpTextModal
					helpText="Our savings-or-free contingency fee is applied to bill savings we achieve for you. For instance, if our fee is 25% and your property tax bill is reduced from $5,000 to $4,000 our fee will be applied to the $1,000 difference. In this case, the final invoice amount would be $250."
					label="Contingency Fee"
					onClose={() => setShowContingencyModal(false)}
				/>
			)}
		</div>
	);
};

export const ConciergeFeatures = ({
	featuresList,
}: {
	featuresList: PlanFeature[];
}) => {
	const [selectedHelp, setSelectedHelp] = useState<{
		text: string;
		label: string;
	} | null>(null);

	return (
		<div className="features">
			<p className="feature-heading mb-1">What's Included?</p>
			<p className="xs mb-1 italic">Click to learn more about each service!</p>
			<div className="feature-list">
				{featuresList.map((item, index) => (
					<div
						className="list-item"
						key={index}
						onClick={() => {
							if (item.helpText) {
								setSelectedHelp({ text: item.helpText, label: item.label });
							}
						}}
						style={{ cursor: item.helpText ? "pointer" : "default" }}>
						<div className="list-item-label">
							<span className={item.active ? "icon" : "icon fill-denim-5"}>
								<CircleCheck />
							</span>
							<div className="list-item-label-text">
								<p className={item.active ? "sm " : "sm denim_5"}>
									{item.label}{" "}
								</p>
								{item.subtext && <p className="xs">{item.subtext}</p>}
								{item.helpText && (
									<InfoCircle
										className={
											"info-icon " +
											(item.active ? "fill-denim" : "fill-denim-5")
										}
										onClick={(e: React.MouseEvent) => {
											e.stopPropagation();
											if (item.helpText) {
												setSelectedHelp({
													text: item.helpText,
													label: item.label,
												});
											}
										}}
									/>
								)}
							</div>
						</div>
						{item.value && (
							<p className="list-item-value extra-small semibold">
								{item.value}
							</p>
						)}
					</div>
				))}
			</div>
			{selectedHelp && (
				<HelpTextModal
					helpText={selectedHelp.text}
					label={selectedHelp.label}
					onClose={() => setSelectedHelp(null)}
				/>
			)}
		</div>
	);
};

export const SavingsBox = () => {
	return (
		<div className="saving">
			<ShieldCheck className="shield-icon" />
			<div className="savings-content">
				<p className="savings-header xs semibold">Savings Guarantee</p>
				<p className="savings-text tiny">
					We'll save you more than our yearly fee, or your money back!
				</p>
			</div>
		</div>
	);
};

export default ConciergeOptionCard;
