import { createMachine, assign, setup } from "xstate";
import { ExemptionQuestions } from "utils/types/exemption-questions";
import { PropertyDetails } from "utils/types";

export const states_with_viable_exemptions = [
	"AL",
	"AK",
	"AR",
	"CA",
	"DC",
	"FL",
	"GA",
	"HI",
	"ID",
	"IL",
	"IN",
	"IA",
	"LA",
	"ME",
	"MD",
	"MA",
	"MI",
	"MN",
	"MS",
	"NV",
	"NM",
	"NY",
	"OK",
	"PA",
	"RI",
	"SC",
	"TX",
	"UT",
	"VT",
] as const;

export const states_with_viable_exemptions_savings: Record<
	(typeof states_with_viable_exemptions)[number],
	number
> = {
	AL: 53,
	AK: 975,
	AR: 425,
	CA: 497,
	DC: 714,
	FL: 743,
	GA: 503,
	HI: 1_344,
	ID: 525,
	IL: 938,
	IN: 370,
	IA: 175,
	LA: 382,
	ME: 292,
	MD: 1_422,
	MA: 3_984,
	MI: 2_088,
	MN: 1_129,
	MS: 300,
	NV: 2_575,
	NM: 85,
	NY: 543,
	OK: 134,
	PA: 529,
	RI: 668,
	SC: 476,
	TX: 1_983,
	UT: 1_204,
	VT: 350,
};

export const property_has_viable_exemption = (
	details?: Pick<PropertyDetails, "state" | "accepting_exemptions">
) => {
	return (
		!!details?.accepting_exemptions &&
		states_with_viable_exemptions.includes(
			(details?.state ?? "") as (typeof states_with_viable_exemptions)[number]
		)
	);
};

export const researchExemptionQuestionFlowMachine = setup({
	types: {
		context: {} as ExemptionQuestions.FlowContext,
		events: {} as ExemptionQuestions.FlowEvent,
		input: {} as Partial<ExemptionQuestions.FlowContext>,
	},
	actions: {
		updateContext: assign(({ context, event }) => {
			if ("context" in event) {
				return {
					...context,
					...event.context,
				};
			}
			return context;
		}),
	},
	delays: {},
}).createMachine({
	id: "RESEARCH_EXEMPTION",
	context: ({ input }) => ({ ...input }) as ExemptionQuestions.FlowContext,
	initial: "IDLE",
	states: {
		IDLE: {},
		INIT: {
			always: [
				{
					target: "PRIMARY_RESIDENCE",
					guard: ({ context, event }) => {
						return context.primaryResidenceValue === undefined;
					},
				},
				{
					target: "CURRENTLY_RECEIVING",
					guard: ({ context, event }) =>
						context.currentlyReceivingValue === undefined,
				},
				{
					target: "MOVE_IN_DATE",
					guard: ({ context, event }) => context.moveInDateValue === undefined,
				},
				{
					target: "DOB",
					guard: ({ context, event }) => context.dobValue === undefined,
				},
				{
					target: "NON_AGREEMENT_CONFIRMATION",
				},
			],
		},
		PRIMARY_RESIDENCE: {
			entry: [
				assign({
					exemptionsProgress: context => (0 / 4) * 100,
				}),
			],
			on: {
				next: {
					target: "CURRENTLY_RECEIVING",
				},
			},
		},
		CURRENTLY_RECEIVING: {
			entry: [
				assign({
					exemptionsProgress: context => (1 / 4) * 100,
				}),
			],
			on: {
				back: {
					target: "PRIMARY_RESIDENCE",
					actions: assign({
						currentlyReceivingValue: context => undefined,
					}),
				},
				next: {
					target: "MOVE_IN_DATE",
				},
			},
		},
		MOVE_IN_DATE: {
			entry: [
				assign({
					exemptionsProgress: context => (2 / 4) * 100,
				}),
			],
			on: {
				back: {
					target: "CURRENTLY_RECEIVING",
				},
				next: {
					target: "DOB",
				},
			},
		},
		DOB: {
			entry: [
				assign({
					exemptionsProgress: context => (3 / 4) * 100,
				}),
			],
			on: {
				back: {
					target: "MOVE_IN_DATE",
				},
				next: {
					target: "NON_AGREEMENT_CONFIRMATION",
				},
			},
		},
		NON_AGREEMENT_CONFIRMATION: {
			entry: [
				assign({
					exemptionsProgress: context => (4 / 4) * 100,
				}),
			],
		},
	},
	on: {
		update_context: {
			actions: "updateContext",
		},
		restart: {
			target: ".INIT",
		},
	},
});
