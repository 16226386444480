"use client";
import React, { useEffect, useState } from "react";
import ConciergeOptionTab from "./ConciergeOptionTab";
import {
	ConciergeOptionCard,
	ConciergeOptionSlider,
} from "./ConciergeOptionCard";
import NoConciergeOptionCard from "./NoConciergeOptionCard";
import { usePropertyContext } from "../context";
import { usePathname, useRouter } from "next/navigation";
import client from "utils/client";
import ButtonV2 from "elements/ButtonV2";
import { useAuthContext } from "context/AuthContext";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import PropertyModal from "../Modal";
import Warning from "public/account/survey/concierge/warning.svg";
import CircleCheck from "public/account/survey/concierge/check-circle-green.svg";
import ConciergePayment from "../Survey/questions/ConciergePayment";
import { useConciergePaymentModalFlag } from "./ConciergePaymentModal";
import ConciergeHeader from "public/account/survey/concierge/concierge-header.svg";
import ConciergeHeaderBG from "public/account/survey/concierge/concierge-header-bg.png";
import Image from "next/image";
import ConciergeConfirmationContent from "./ConciergeConfirmation";

export const DEFAULT_CONTINGENCY_PERCENTAGE = 25;
export const DEFAULT_CONCIERGE_PLAN_PRICE_ANNUALLY = 199;
export const DEFAULT_CONCIERGE_PLAN_PRICE_MONTHLY = 20;
export const DEFAULT_CONCIERGE_PLAN_PRICE_MIN_ANNUALLY = 99;
export const DEFAULT_CONCIERGE_PLAN_PRICE_MAX_ANNUALLY = 299;
export const DEFAULT_CONCIERGE_PLAN_PRICE_MIN_MONTHLY = 10;
export const DEFAULT_CONCIERGE_PLAN_PRICE_MAX_MONTHLY = 30;

export interface IConciergeContext {
	planPrice: number;
	setPlanPrice: React.Dispatch<React.SetStateAction<number>>;
	sliderMin: number;
	setSliderMin: React.Dispatch<React.SetStateAction<number>>;
	sliderMax: number;
	setSliderMax: React.Dispatch<React.SetStateAction<number>>;
	billingCycle: string;
	setBillingCycle: React.Dispatch<React.SetStateAction<string>>;
	conciergePlan: string;
	setConciergePlan: React.Dispatch<React.SetStateAction<string>>;
	conciergePaid: boolean;
	submitPayment: (args: {
		card_number: string;
		card_cvc: string;
		card_exp_month: string;
		card_exp_year: string;
		conciergeAddOnSelected?: boolean;
		extraAmount?: number;
	}) => Promise<void>;
	paymentConfirmed: boolean;
	paymentErrored: boolean;
	setPaymentErrored: React.Dispatch<React.SetStateAction<boolean>>;
	paymentSubmitting: boolean;
	cancelSubscription: () => Promise<void>;
	cancellationConfirmed: boolean;
	cancellationErrored: boolean;
	cancelling: boolean;
	downgradeInterestedTier: () => Promise<void>;
	downgradingConfirmed: boolean;
	downgradingErrored: boolean;
	downgrading: boolean;
	handleConciergePlanSelect: () => Promise<void>;
}

export const ConciergeContext = React.createContext<IConciergeContext>({
	planPrice: DEFAULT_CONCIERGE_PLAN_PRICE_ANNUALLY,
	setPlanPrice: () => {},
	sliderMin: DEFAULT_CONCIERGE_PLAN_PRICE_MIN_ANNUALLY,
	setSliderMin: () => {},
	sliderMax: DEFAULT_CONCIERGE_PLAN_PRICE_MAX_ANNUALLY,
	setSliderMax: () => {},
	billingCycle: "annually",
	setBillingCycle: () => {},
	conciergePlan: "",
	setConciergePlan: () => {},
	conciergePaid: false,
	submitPayment: async (args: {
		card_number: string;
		card_cvc: string;
		card_exp_month: string;
		card_exp_year: string;
		conciergeAddOnSelected?: boolean;
		extraAmount?: number;
	}) => {},
	paymentConfirmed: false,
	paymentErrored: false,
	setPaymentErrored: () => {},
	paymentSubmitting: false,
	cancelSubscription: async () => {},
	cancellationConfirmed: false,
	cancellationErrored: false,
	cancelling: false,
	downgradeInterestedTier: async () => {},
	downgradingConfirmed: false,
	downgradingErrored: false,
	downgrading: false,
	handleConciergePlanSelect: async () => {},
});

export const useConciergeContext = () => React.useContext(ConciergeContext);

export const ConciergeContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const { userData, setUserData } = useAuthContext();

	const { showConciergePaymentModalFlag, setShowConciergePaymentModalFlag } =
		useConciergePaymentModalFlag();

	const [planPrice, setPlanPrice] = useState(199);
	const [sliderMin, setSliderMin] = useState(99);
	const [sliderMax, setSliderMax] = useState(299);
	const [conciergePlan, setConciergePlan] = useState("");
	const [billingCycle, setBillingCycle] = useState("annually");
	const [conciergePaid, setConciergePaid] = useState(false);

	const [cancellationConfirmed, setCancellationConfirmed] = useState(false);
	const [cancellationErrored, setCancellationErrored] = useState(false);
	const [cancelling, setCancelling] = useState(false);

	const [downgradingErrored, setDowngradingErrored] = useState(false);
	const [downgradingConfirmed, setDowngradingConfirmed] = useState(false);
	const [downgrading, setDowngrading] = useState(false);

	const [paymentConfirmed, setPaymentConfirmed] = useState(false);
	const [paymentErrored, setPaymentErrored] = useState(false);
	const [paymentSubmitting, setPaymentSubmitting] = useState(false);

	const trackEvent = useEventTracker();
	const router = useRouter();
	const { details } = usePropertyContext();

	useEffect(() => {
		const activeConciergeSubsription = userData?.active_subscription.find(sub =>
			sub.product.includes("concierge")
		);
		if (activeConciergeSubsription) {
			setConciergePaid(true);
		} else {
			setConciergePaid(false);
		}
	}, [userData?.active_subscription]);

	useEffect(() => {
		if (userData?.interested_tier?.includes("concierge")) {
			setConciergePlan(userData?.interested_tier);
		}
	}, [userData?.interested_tier]);

	useEffect(() => {
		if (downgradingConfirmed || paymentConfirmed) {
			setShowConciergePaymentModalFlag(false);
		}
	}, [downgradingConfirmed, paymentConfirmed]);

	useEffect(() => {
		if (billingCycle == "annually") {
			setSliderMin(DEFAULT_CONCIERGE_PLAN_PRICE_MIN_ANNUALLY);
			setSliderMax(DEFAULT_CONCIERGE_PLAN_PRICE_MAX_ANNUALLY);
			setPlanPrice(DEFAULT_CONCIERGE_PLAN_PRICE_ANNUALLY);
		} else {
			setSliderMin(DEFAULT_CONCIERGE_PLAN_PRICE_MIN_MONTHLY);
			setSliderMax(DEFAULT_CONCIERGE_PLAN_PRICE_MAX_MONTHLY);
			setPlanPrice(DEFAULT_CONCIERGE_PLAN_PRICE_MONTHLY);
		}
	}, [billingCycle]);

	const submitPayment = async (args: {
		card_number: string;
		card_cvc: string;
		card_exp_month: string;
		card_exp_year: string;
		conciergeAddOnSelected?: boolean;
		extraAmount?: number;
	}) => {
		try {
			setPaymentSubmitting(true);
			const response = await client.payConciergeInvoice({
				product: conciergePlan,
				cadence: billingCycle,
				card_number: args.card_number,
				card_cvc: args.card_cvc,
				card_exp_month: args.card_exp_month,
				card_exp_year: args.card_exp_year,
				plan_price: planPrice,
			});
			if (response.status !== 200) {
				setPaymentErrored(true);
				console.error("Failed to pay invoice");
				return;
			} else {
				const userData = await client.getUserData();
				setUserData(userData);
				setPaymentConfirmed(true);
				setConciergePaid(true);
			}
		} catch (err) {
			setPaymentErrored(true);
			console.error(err);
		} finally {
			setPaymentSubmitting(false);
		}
	};

	const cancelSubscription = async () => {
		const subscriptionId =
			userData?.active_subscription.find(sub =>
				sub.product.includes("concierge")
			)?.subscriptionId || null;
		if (subscriptionId) {
			setCancelling(true);
			//cancelling
			try {
				const response = await client.cancelConciergeSubsription({
					subscription_id: subscriptionId,
				});
				if (response.status === 200) {
					const userData = await client.getUserData();
					setUserData(userData);
					setCancellationConfirmed(true);
					trackEvent({
						eventName:
							constants.EVENTS.Concierge_cancellation_after_payment_action,
						data: {
							CustomerId: userData?.id,
							action: "Yes Cancel",
						},
					});
				} else setCancellationErrored(true);
			} catch (error) {
				setCancellationErrored(true);
			} finally {
				setCancelling(false);
			}
		}
	};

	const downgradeInterestedTier = async () => {
		try {
			setDowngrading(true);
			const response = await client.downgradeInterestedTier();
			if (response.status === 200) {
				const userData = await client.getUserData();
				setUserData(userData);
				setDowngradingConfirmed(true);
				trackEvent({
					eventName:
						constants.EVENTS.Concierge_onboarding_upgrade_payment_action,
					data: {
						CustomerId: userData?.id,
						action: "cancel",
					},
				});
			} else setDowngradingErrored(true);
		} catch (error) {
			setDowngradingErrored(true);
			console.error("Error downgrading account tier", error);
		} finally {
			setDowngrading(false);
		}
	};

	const handleConciergePlanSelect = async () => {
		setConciergePlan("concierge");
		await client.upgradeInterestedTier({
			interestedTier: "concierge",
		});
		const userData = await client.getUserData();
		setUserData(userData);
	};

	return (
		<ConciergeContext.Provider
			value={{
				billingCycle,
				setBillingCycle,
				conciergePlan,
				setConciergePlan,
				conciergePaid,
				submitPayment,
				paymentConfirmed,
				paymentErrored,
				setPaymentErrored,
				paymentSubmitting,
				cancelSubscription,
				cancellationConfirmed,
				cancellationErrored,
				cancelling,
				downgradeInterestedTier,
				downgradingConfirmed,
				downgradingErrored,
				downgrading,
				handleConciergePlanSelect,
				planPrice,
				setPlanPrice,
				sliderMin,
				setSliderMin,
				sliderMax,
				setSliderMax,
			}}>
			{children}
		</ConciergeContext.Provider>
	);
};

export const ConciergeOptionsCards = ({
	onOptionSelect,
	handleSkip,
	hideCta = false,
	showSlider = true,
}: {
	onOptionSelect: () => void;
	handleSkip: () => void;
	hideCta?: boolean;
	showSlider?: boolean;
}) => {
	const { details } = usePropertyContext();
	return (
		<>
			<div className="concierge-options-cards">
				<NoConciergeOptionCard
					contingencyPercentage={
						details?.contingency
							? details?.contingency
							: DEFAULT_CONTINGENCY_PERCENTAGE
					}
					onOptionSelect={handleSkip}
					hideCTA={hideCta}
				/>
				<ConciergeOptionCard
					contingencyPercentage={
						details?.contingency
							? details?.contingency
							: DEFAULT_CONTINGENCY_PERCENTAGE
					}
					onOptionSelect={onOptionSelect}
					hideCTA={hideCta}>
					{showSlider && <ConciergeOptionSlider />}
				</ConciergeOptionCard>
			</div>
		</>
	);
};

const ConciergePage = () => {
	const { userData, setUserData } = useAuthContext();
	const { details } = usePropertyContext();

	const {
		conciergePlan,
		setConciergePlan,
		billingCycle,
		setBillingCycle,
		conciergePaid,
		cancelling,
		cancellationConfirmed,
		cancellationErrored,
		cancelSubscription,
		handleConciergePlanSelect,
	} = useConciergeContext();

	const [cancelModalOpen, setCancelModalOpen] = useState(false);

	const router = useRouter();

	const trackEvent = useEventTracker();

	useEffect(() => {
		trackEvent({
			eventName: constants.EVENTS.Concierge_upgrade_page_viewed,
			data: {
				CustomerId: userData?.id,
			},
		});
		const internalScrollingComp = document.getElementById(
			"property-layout-content"
		);
		if (internalScrollingComp) {
			internalScrollingComp.scrollTo(0, 0);
		}
	}, []);

	const handleSkip = () => {
		router.push(`/account/properties/${details?.id}`);
	};

	const showOverviewPage = () => {
		router.push(`/account/properties/${details?.id}`);
	};

	const handlePaymentSuccess = async () => {
		const userData = await client.getUserData();
		setUserData(userData);
		trackEvent({
			eventName: constants.EVENTS.Concierge_upgrade_payment_action,
			data: {
				CustomerId: userData?.id,
				action: "submit",
			},
		});
	};

	const openCancellationModal = () => {
		setCancelModalOpen(true);
		trackEvent({
			eventName: constants.EVENTS.Concierge_cancellation_after_payment_viewed,
			data: {
				CustomerId: userData?.id,
			},
		});
	};

	const getConciergePageContent = () => {
		if (conciergePaid) {
			return (
				<>
					<ConciergeConfirmationContent />
					<div className="btn-container">
						<ButtonV2 size="small" variant="primary" onClick={showOverviewPage}>
							Go To Overview
						</ButtonV2>
						<p
							className="cancel-subscription sm"
							onClick={openCancellationModal}>
							cancel concierge service
						</p>
					</div>
				</>
			);
		} else if (
			(!!conciergePlan && !!billingCycle) ||
			userData?.interested_tier?.includes("concierge")
		) {
			return (
				<>
					<ConciergeOptionTab />
					<div className="concierge-page-payment-wrapper mt-3">
						<ConciergePayment handleContinueClick={handlePaymentSuccess} />
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className="concierge-header">
						<div className="nothern-light-gradient-wrapper">
							<Image
								src={ConciergeHeaderBG}
								alt="Concierge Header Background"
							/>
						</div>
						<div className="concierge-header-img-wrapper">
							<ConciergeHeader className="concierge-header-img" />
						</div>
						<p className="fancy-subtitle h4">The No. 1 Homeowner Membership</p>
						<p className="semibold xs denim-dark text-center">
							Join the homeowners who save the most time and money! A Concierge
							membership is all you need.
						</p>
					</div>
					<ConciergeOptionTab />
					<div className="mb-3"></div>
					<ConciergeOptionsCards
						handleSkip={handleSkip}
						onOptionSelect={handleConciergePlanSelect}
					/>
				</>
			);
		}
	};

	const dontCancelSubsciption = () => {
		setCancelModalOpen(false);
		trackEvent({
			eventName: constants.EVENTS.Concierge_cancellation_after_payment_action,
			data: {
				CustomerId: userData?.id,
				action: "No Keep",
			},
		});
	};

	return (
		<div className="concierge-page-wrapper">
			{getConciergePageContent()}
			{cancelModalOpen && (
				<PropertyModal onAttemptExit={() => setCancelModalOpen(false)}>
					<div className="cancel-subsciption-modal-wrapper">
						<div className="confirmation-modal-wrapper">
							{cancellationConfirmed ? (
								<>
									<div className="warning-img-wrapper">
										<CircleCheck />
									</div>
									<p className="xl bold">Membership Canceled</p>
									<ButtonV2
										size="small"
										variant="primary"
										className="close-btn"
										onClick={showOverviewPage}>
										Close
									</ButtonV2>
								</>
							) : (
								<>
									<div className="warning-img-wrapper">
										<Warning />
									</div>
									<p className="xl bold">Cancel Concierge Services?</p>
									<p className="sm text-center">
										You will lose access to Concierge features, services and
										support. If we've achieved successful savings already from a
										service with a contingecy fee such as appeals, exemption
										refunds or bill reductions, you will still be expected to
										pay those invoices.
									</p>
									{cancellationErrored && (
										<p className="rust">
											There was an error cancelling your subscription. Please
											try again.
										</p>
									)}
									<div className="btn-container">
										<ButtonV2
											size="small"
											variant="destructive-outline"
											onClick={cancelSubscription}
											disabled={cancelling}>
											{cancelling ? "Cancelling..." : "Yes, Cancel Concierge"}
										</ButtonV2>
										<ButtonV2
											size="small"
											variant="primary-outline"
											onClick={dontCancelSubsciption}>
											No, Keep Finding Savings
										</ButtonV2>
									</div>
								</>
							)}
						</div>
					</div>
				</PropertyModal>
			)}
		</div>
	);
};

export default function Page() {
	return (
		<ConciergeContextProvider>
			<ConciergePage />
		</ConciergeContextProvider>
	);
}
